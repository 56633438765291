<div class="L-QuickSetup-Content _configure-view">
  <div class="L-QuickSetup-Header">
    <h2 class="L-QuickSetup-Title">Quick Setup</h2>
    <p class="L-QuickSetup-Description">Select which reward to give your visitors after they subscribe.</p>
  </div>
  <div class="L-QuickSetup-Preview-Wrap">
    <div class="L-QuickSetup-Preview">
      <pf-coupon-box-preview
        [params]="params"
        [withBrowser]="false"
        [showBackground]="false"
      ></pf-coupon-box-preview>
    </div>
  </div>
  <div class="L-QuickSetup-Controls">
    <div #controlsRef></div>
  </div>
</div>