<div class="StepperMenu">
  <div class="StepperMenu-Item" 
       *ngFor="let step of steps"
       [class._passed]="step.passed"
       [class._clickable]="clickable && (step.clickable !== false)">
    <ng-container *ngTemplateOutlet="step.url ? routerBased : basic; context: {step: step}"></ng-container>
  </div>
</div>

<ng-template #routerBased let-step="step">
  <a class="StepperMenu-Item-Link _has-url"
     [routerLink]="[step.url]"
     [class._passed]="step.passed"
     [state]="{data: step}"
     routerLinkActive="_active"
  >
    <span (click)="onStepClick(step, $event)">
      {{step.title}}
    </span>
  </a>
</ng-template>

<ng-template #basic let-step="step">
  <a class="StepperMenu-Item-Link" [class._passed]="step.passed" (click)="onStepClick(step, $event)">
    {{step.title}}
  </a>
</ng-template>
