<h1 mat-dialog-title>
  UPGRADE
  <button class="mat-dialog-close" (click)="dialogRef.close()">
    <span>&times;</span>
  </button>
</h1>
<div mat-dialog-content>
  <p>
    This is a premium plugin and requires you to be on higher plan.
  </p>
</div>
<div mat-dialog-actions class="mb-0 d-flex justify-content-end">
  <button class="pf-button md outline gray" (click)="dialogRef.close()">Cancel</button>
  <button class="pf-button md filled blue ml-3" (click)="letsGo()">Review Plan Options!</button>
</div>

<pf-loading-overlay></pf-loading-overlay>
