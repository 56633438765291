import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { isEqual } from 'lodash'
import { fromEvent } from 'rxjs'
import { Subscription } from 'rxjs'
import { environment } from '../../../../../../environments/environment'
import { UserService } from '../../../../../core/services/user.service'
import { CouponBoxNotificationParams } from '../../../../../shared/models/campaign/coupon-box-notification.model'
import { ConfigTriggerMonitoringService } from '../../../../../shared/modules/triggers-page/config-trigger-monitoring/config-trigger-monitoring.service'
import { PreviewService } from '../../../services/preview.service'
import { IframeHelperService } from '../../../../../shared/services/iframe.service'
import { PreviewDeviceTitle } from '../../../../../shared/modules/preview-toggle/models/device-preview'
import { PreviewState } from '../../../../../shared/modules/preview-toggle/models/preview-state'
import { IframeEventType } from '../../../../../shared/modules/triggers-page/models/trigger-events.model'
import { distinctUntilChanged } from 'rxjs/operators'
// import * as logRocketJs from '!raw-loader!../../../../../../app_assets/scripts/LogRocket.min.js'

@Component({
  selector: 'pf-coupon-box-preview',
  templateUrl: './coupon-box-preview.component.html',
  styleUrls: ['./coupon-box-preview.component.scss'],
})
export class CouponBoxPreviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fullVersion: boolean
  @Input() params: CouponBoxNotificationParams | any
  @Input() showBackground: boolean = true
  @Input() flexContainer: boolean = false
  @Input() thumbPreview: boolean = false
  @Input() isTriggersStep: boolean = false
  @Input() withBrowser: boolean = false
  @Input() scrolling: 'yes' | 'no' | 'auto' = 'no'
  @Input() disablePopupScale = false
  @Input() fullScreenPreview = false

  @ViewChild('iFrame', { static: true }) iFrameRef: ElementRef
  @Output() iframeLoaded = new EventEmitter<string>()
  @Output() iframeData = new EventEmitter<any>()
  @HostBinding('class._has-background') hasBackground: boolean = true
  @HostBinding('class._is-fullversion') isFullVersion: boolean = true
  @HostBinding('class._is-thumb-preview') isThumbPreview: boolean = false

  doc: any
  production = environment.production
  notLoaded: boolean = true
  subscription: Subscription = new Subscription()

  previewData: { params: CouponBoxNotificationParams }
  previewElement = null
  previewClass = { 'proof-factor-coupon-preview': true }
  iFrameSRC = null
  sizes: any

  constructor(
    public elementRef: ElementRef,
    public userService: UserService,
    public previewService: PreviewService,
    private monitoringTriggerService: ConfigTriggerMonitoringService,
    private iframeHelperService: IframeHelperService
  ) {
  }

  ngOnInit(): void {
    this.previewService.previewDevice = this.previewService.devicePreviewButtons[0]
    this.hasBackground = this.showBackground
    this.isFullVersion = this.fullVersion
    this.isThumbPreview = this.thumbPreview
    this.updateiFrameSRC()
    window.document.addEventListener('PFWidgetEvent', (data: CustomEvent) => {
      this.iframeData.emit(data)
      // reset preview state btns to default when teaser was clicked
      if (data.detail?.type === IframeEventType.PFTriggerButtonClick) {
        this.previewService.previewState = PreviewState.default
      }
    }, false)
    this.subscription.add(
      this.previewService.previewState$.asObservable().subscribe(value => {
        window.dispatchEvent(new Event('resize'))
        this.updatePreviewParams()
        }),
    )
    this.subscription.add(
      this.previewService.previewDevice$.asObservable().subscribe(value => {
        window.dispatchEvent(new Event('resize'))
        this.updatePreviewSizes()
      }),
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.previewData) {
      this.previewData = {} as any
    }
    if (changes.params && changes.params.currentValue) {
      const currentValue = JSON.parse(JSON.stringify(changes.params.currentValue))
      if (changes.params && !isEqual(this.previewData.params, currentValue)) {
        this.previewService.popupParams = currentValue
        this.previewData.params = {
          ...currentValue,
          disablePopupScale: this.previewService.previewDevice.isMobile && this.disablePopupScale,
          isPhonePreview: this.previewService.previewDevice.isMobile,
          previewState: this.previewService.previewState,
          detectedCountry: this.userService.getUserCountry(),
          showFullVersion: this.fullVersion,
          withBrowser: this.withBrowser,
        }
        this.updatePreviewParams()
      }
    }
    if (window['PROOF_FACTOR_PREVIEW_LOADED']) {
      this.notLoaded = false
    }
  }

  updateiFrameSRC() {
    const getGeneratedPageURL = ({ html }) => {

      // <script>${logRocketJs.default}</script>
      // <script>
      //   LogRocket.init('morpheus/one', {
      //     rootHostname: 'one.store',
      //     mergeIframes: true,
      //     parentDomain: "${window.location.origin}"
      //   })
      // </script>
      const source = `
        <html>
          <head>
          </head>
          <body style="margin: 0; padding: 0; border: 0;">
            ${html || ''}
          </body>
        </html>
      `

      return this.iframeHelperService.getIframeUrl(this.iFrameRef, source)
    }

    this.iFrameSRC = getGeneratedPageURL({
      html: `<proof-factor-coupon-preview id="proof-factor-coupon-preview"></proof-factor-coupon-preview>
             <script type="application/javascript" src="${
        environment.widgetPreviewURL
      }?timestamp=${+new Date()}" id="widget-script" data-source="dashboard"></script>`,
    })
  }

  onLoad() {
    if (this.iFrameRef) {
      this.doc = this.iFrameRef.nativeElement.contentDocument || this.iFrameRef.nativeElement.contentWindow
      this.previewElement = this.doc.getElementById('proof-factor-coupon-preview')
      this.updatePreviewParams()
      this.iframeLoaded.emit('loaded')
      if (this.isTriggersStep) {
        this.trackScrollEvent()
      }
    }
  }

  trackScrollEvent() {
    const iFrameWindow = this.iFrameRef.nativeElement.contentWindow
    this.subscription.add(
      fromEvent(iFrameWindow, 'scroll').subscribe(() => {
        const scrollableHeight = iFrameWindow.document.body.scrollHeight - iFrameWindow.innerHeight
        const percentScrolled = Math.round(
          (100 * iFrameWindow.pageYOffset) / scrollableHeight,
        )
        this.monitoringTriggerService.scrollNext = percentScrolled
      }),
    )
  }

  updatePreviewParams() {
    if (this.previewElement && this.previewData.params) {
      this.previewElement.params = {
        ...this.previewData.params,
        ...{
          disablePopupScale: this.previewService.previewDevice.isMobile && this.disablePopupScale,
          isPhonePreview: this.previewService.previewDevice.isMobile,
          previewState: this.previewService.previewState,
          detectedCountry: this.userService.getUserCountry(),
          showFullVersion: this.fullVersion,
          withBrowser: this.withBrowser,
          devicePreview: this.fullScreenPreview,
        },
      }
    }
  }

  toPx(value: number) {
    return !isNaN(value) ? `${value.toFixed(0)}px` : null
  }

  updatePreviewSizes() {
    const _sizes = {}

    if (!this.previewService.previewDevice) {
      return false
    }

    if (!this.thumbPreview) {
      _sizes['width'] = this.fullScreenPreview ? '100%' : this.previewService.previewDevice.width
      _sizes['min-width'] = this.fullScreenPreview ? '100%': this.previewService.previewDevice.minWidth

      _sizes['height'] = this.fullScreenPreview ? '100%': this.previewService.previewDevice.height
      _sizes['min-height'] = this.fullScreenPreview ? '100%': this.previewService.previewDevice.minHeight

      this.sizes = _sizes
      this.updatePreviewParams()
    }
  }
}
