import {Injectable} from '@angular/core'
import {finalize, tap} from 'rxjs/operators'
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/compat/storage'
import {Store} from '@ngrx/store'
import {StoreState} from '../../store/store.state'
import { ShowLoading, HideLoading } from '../../store/loading/loading.actions'

@Injectable()
export class FileStorageService {
  mimeTypeToExtension = {
    'image/png': 'png',
    'image/jpeg': 'jpg',
    'image/jpg': 'jpg',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/tiff': 'tiff',
    'image/vnd.microsoft.icon': 'ico',
    'image/svg+xml': 'svg',
    'image/webp': 'webp',
    // HEIF/HEIC formats
    'image/heif': 'heif',
    'image/heic': 'heic',
    // RAW formats
    'image/x-canon-cr2': 'cr2',
    'image/x-nikon-nef': 'nef',
    'image/x-sony-arw': 'arw',
    // Other
    'image/x-tiff': 'tif',
    'image/x-portable-pixmap': 'ppm',
    'image/x-portable-graymap': 'pgm',
    'image/x-portable-bitmap': 'pbm',
  }

  constructor(
    private angularFireStorage: AngularFireStorage,
    private store: Store<StoreState>,
  ) {
  }

  upload(path, file, callback, metaData?: {customMetadata: any}) { // TODO: find a way to get rid of callback
    const task = metaData ? this.angularFireStorage.upload(path, file, metaData) : this.angularFireStorage.upload(path, file)
    this.store.dispatch(new ShowLoading('uploadFile'))
    return task.snapshotChanges()
      .pipe(finalize(() => {
        this.angularFireStorage.ref(path).getMetadata()
          .pipe(tap(data2 => {
            this.store.dispatch(new HideLoading('uploadFile'))
          }))
          .subscribe(data => callback(data))
      }))
  }

  uploadTask(path, file): AngularFireUploadTask {
    return this.angularFireStorage.upload(path, file)
  }

  private _base64ToBlob(base64: string, mime: string): Blob {
    const byteString = atob(base64.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mime })
}

  public base64ToFile(base64: string, filename: string): File {
    const mime = base64.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)[1]
    const blob = this._base64ToBlob(base64, mime)
    return new File([blob], filename, { type: mime })
  }

  static generateFileName(file) {
    const extension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2)
    return `${Math.random()
      .toString(36)
      .substring(7)}.${extension}`
  }
}
