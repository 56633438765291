import { Component, Inject, OnInit } from '@angular/core'
import { CampaignCard, CampaignCardValues } from '../../../models/campaign-cards.model'
import { SegmentAnalyticsService } from '../../../../../services/segment-analytics.service'
import { EmailAutomationTypeEnum } from '../../../../../../pages/emails/components/email-automations/enums/email-automation-type.enum'
import { AutomationType } from '../../../../../../pages/sms/modules/sms-automations/models/automation'
import { RouteHeaderUrl } from '../../../../../components/one-header/header-navigation.model'
import { Router } from '@angular/router'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'

@Component({
  templateUrl: './card-new-modal.component.html',
  styleUrls: ['./card-new-modal.component.scss']
})

export class CardNewModalComponent implements OnInit {
  sectionOpen = {
    broadcasts: true,
    abandonment: true,
    upsell: false,
    loyalty: false,
    welcome: false,
    smsOnboardingStart: true,
    upsellMessage: true,
    upsellCheckout: true,
  }
  routeHeaderUrl = RouteHeaderUrl

  campaignCardMap = {
    [CampaignCardValues.CouponBoxNotification]: 'Coupon Box Pop Up',
    [CampaignCardValues.NewsLetterNotification]: 'Newsletter Pop Up',
    [CampaignCardValues.FortuneWheel]: 'Spin Wheel Pop Up',
    [CampaignCardValues.RecentSalesPop]: 'Recent Sales Pop Up',
    [CampaignCardValues.AggregateSalesPop]: 'Aggregate Sales Pop Up',
    [CampaignCardValues.LiveVisitorCount]: 'Visitor Count Pop Up',
    [CampaignCardValues.AggregateVisitorCount]: 'Aggregate Visitor Count Pop Up',
    [CampaignCardValues.FreeShippingNotification]: 'Free Shipping Pop Up',
    [CampaignCardValues.ProductPopup]: 'Product Announcement Pop Up',
    [CampaignCardValues.SalesNotification]: 'Sales Announcement Pop Up',
    [CampaignCardValues.GiftCardsUnique]: 'Fixed Cash Amount',
    [CampaignCardValues.BuyXGetY]: 'Buy X Get Y',
    [CampaignCardValues.FreeShipping]: 'Free Shipping',
    [CampaignCardValues.Percentage]: 'Percentage',
    [CampaignCardValues.FixedAmount]: 'Fixed Amount',
    [CampaignCardValues.UpsellCheckout]: ' Upsell One-Click Checkout',
  }

  smsAutomationCardMap = {
    [CampaignCardValues.SMSUpsell]: AutomationType.upsell,
    [CampaignCardValues.SMSWinback]: AutomationType.winback,
    [CampaignCardValues.SMSBirthday]: AutomationType.birthday,
    [CampaignCardValues.SMSCartRecovery]: AutomationType.cart,
  }
  emailAutomationCardMap = {
    [CampaignCardValues.BirthdayEmails]: EmailAutomationTypeEnum.Birthday,
    [CampaignCardValues.CartRecoveryEmails]: EmailAutomationTypeEnum.CartRecovery,
    [CampaignCardValues.UpsellEmails]: EmailAutomationTypeEnum.Upsell,
    [CampaignCardValues.WinbackEmails]: EmailAutomationTypeEnum.WinBack,
    [CampaignCardValues.WelcomeNewSubscriberCard]: EmailAutomationTypeEnum.WelcomeSubscribers,
    [CampaignCardValues.WelcomeNewCustomerCard]: EmailAutomationTypeEnum.WelcomeCustomers,
    [CampaignCardValues.WelcomeNewAccountsCard]: EmailAutomationTypeEnum.WelcomeAccounts,
    [CampaignCardValues.ThankYou]: EmailAutomationTypeEnum.ThankYou,
  }

  constructor(
    public dialogRef: MatDialogRef<CardNewModalComponent>,
    private router: Router,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: CampaignCard
  ) {
  }

  ngOnInit(): void {
    this.dialogRef.addPanelClass(['_no-padding', '_custom-scrollbar'])
  }

  getBgImage(image) {
    return `url(${image})`
  }

  closeDialog(preset) {
    this.trackCreateButtonClick(preset)
    this.dialogRef.close(preset)
  }

  trackCreateButtonClick(preset: CampaignCard) {
    const campaignTypes = Object.keys(this.campaignCardMap)
    const emailAutomationTypes = Object.values(EmailAutomationTypeEnum)
    const smsAutomationTypes = Object.values(AutomationType)
    const emailBroadcastType = [CampaignCardValues.BroadcastCoupon, CampaignCardValues.BroadcastEmails, CampaignCardValues.BroadcastNewsletter]
    const smsBroadcastType = [CampaignCardValues.SMSBroadcastCoupon, CampaignCardValues.SMSBroadcastNoCoupon]
    switch (true) {
      case emailAutomationTypes.includes(this.emailAutomationCardMap[preset.value]):
        this.segmentAnalyticsService.track('Create Email Automation', { metadata: { type: this.emailAutomationCardMap[preset.value] } })
        break
      case emailBroadcastType.includes(preset.value):
        this.segmentAnalyticsService.track('Create Email Broadcast', { metadata: { type: preset.value } })
        break
      case smsAutomationTypes.includes(this.smsAutomationCardMap[preset.value]):
        this.segmentAnalyticsService.track('Create SMS Automation', { metadata: { type: this.smsAutomationCardMap[preset.value] } })
        break
      case smsBroadcastType.includes(preset.value):
        this.segmentAnalyticsService.track('Create SMS Broadcast', { metadata: { type: preset.value } })
        break
      case campaignTypes.includes(preset.value):
        this.segmentAnalyticsService.track('Clicked Create - ' + this.campaignCardMap[preset.value])
        break
    }
  }

  showTutorialModal(video) {
    // let header = ''
    // switch (video) {
    //   case VideoTutorial.Upsell:
    //     header = 'One-Click Upsell Tutorial'
    //     break
    //   case VideoTutorial.SpinWheel:
    //     header = 'Spin Wheel Pop Up Tutorial'
    //     break
    //   case VideoTutorial.RecentActivity:
    //     header = 'Recent Activity Pop Up Tutorial'
    //     break
    //   case VideoTutorial.EmailOverview:
    //     header = 'Email Overview'
    // }
    // this.dialog.open(VideoTutorialModalComponent, {
    //   width: '800px',
    //   data: {
    //     video: video,
    //     header: header
    //   }
    // })
  }

  navigateToTutorials(preset) {
    const video = preset.videoTutorial.currentVideo
    if (video) {
      this.trackTutorialClick(preset)
      this.router.navigate([RouteHeaderUrl.tutorials, video.parentID, video.id || video.cloudflare.id])
    } else {
      console.error('No video found for this card', preset)
    }
  }

  trackTutorialClick(preset: CampaignCard) {
    this.segmentAnalyticsService.track(`Clicked Watch Tutorials btn - Start Here - ${preset?.videoTutorial?.segmentEvent}`)
    // if (preset?.videoTutorial?.tutorialSegment === VideoTutorialSegment.Email) {
    //   this.segmentAnalyticsService.track(`Clicked Email Overview Tutorial button`)
    // }
  }
}
