import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { environment } from '../../../../../../environments/environment';
import { SafeLocalStorageService } from '../../../../../core/services/safe-local-storage.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface PhoneModalData {
  title: string,
  acceptButton: string,
  bypassCaptcha?: boolean
}

@Component({
  templateUrl: './phone.modal.component.html',
  styleUrls: ['./phone.modal.component.scss'],
})
export class PhoneModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup
  subscription: Subscription = new Subscription()
  sending = false
  sent = false
  showError = false
  captchaToken = null
  environment = environment

  constructor(public dialogRef: MatDialogRef<PhoneModalComponent>,
              private safeLocalStorageService: SafeLocalStorageService,
              private fb: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: PhoneModalData
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      phone: this.getSavedPhoneNumber() || '',
    })
  }

  submit() {
    this.form.get('phone').markAsDirty()
    if (!this.data.bypassCaptcha && !this.captchaToken) {
      this.showError = true
      return
    }
    if (this.form.valid) {
      this.keepPhoneNumber(this.form.value.phone)
      this.dialogRef.close({ phone: this.form.value.phone, captchaToken: this.captchaToken })
    }
  }

  getSavedPhoneNumber() {
    return this.safeLocalStorageService.getItem('test-sms-number')
  }

  keepPhoneNumber(number: string) {
    this.safeLocalStorageService.setItem('test-sms-number', number)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  onVerify(token: string) {
    this.captchaToken = token
    this.showError = false
  }

  onExpired(response: any) {
    this.captchaToken = null
  }

  onError(error: any) {
    console.error('error verifying captcha:', error)
  }
}
