<div class="L-QuickSetup-Header text-center">
  <h2 class="L-QuickSetup-Title">
    <ng-container [ngSwitch]="pluginType">
      <ng-container *ngSwitchCase="campaignPluginName.CouponBoxNotification">
        Pop up with Reward
      </ng-container>
      <ng-container *ngSwitchCase="campaignPluginName.FortuneWheel">
        Spin Wheel
      </ng-container>
      <ng-container *ngSwitchCase="campaignPluginName.NewsLetterNotification">
        Pop up without Reward
      </ng-container>
    </ng-container>
  </h2>
  <p class="L-QuickSetup-Description mb-3">Configure your campaign in a few steps.</p>
</div>
<div class="L-QuickSetup-Start">
  <div class="L-QuickSetup-Start-Cell">
    <img class="L-QuickSetup-Img" src="/app_assets/quick-setup/quick-setup.svg" alt="">
    <button class="pf-button filled green md" (click)="startQuickSetup()">Quick Setup</button>
    <div class="mt-2">1 click setup (Recommended)</div>
  </div>
  <div class="L-QuickSetup-Start-Or">OR</div>
  <div class="L-QuickSetup-Start-Cell">
    <ng-container [ngSwitch]="pluginType">
      <ng-container *ngSwitchCase="campaignPluginName.FortuneWheel">
        <img class="L-QuickSetup-Img" src="/app_assets/quick-setup/custom-setup.svg" alt="">
      </ng-container>
      <ng-container *ngSwitchCase="campaignPluginName.CouponBoxNotification">
        <img class="L-QuickSetup-Img" src="/app_assets/quick-setup/custom-setup.svg" alt="">
      </ng-container>
      <ng-container *ngSwitchCase="campaignPluginName.NewsLetterNotification">
        <img class="L-QuickSetup-Img" src="/app_assets/quick-setup/custom-setup-newsletter.svg" alt="">
      </ng-container>
    </ng-container>
    <button class="pf-button filled blue md" (click)="startCustomSetup()">Custom Setup</button>
    <div class="mt-2 text-muted">4 min setup</div>
  </div>
</div>