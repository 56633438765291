import { Component, EventEmitter, Input, Output } from '@angular/core'
import { StepperMenu } from '../../models/stepper-menu.model'

@Component({
  selector: 'pf-stepper-menu',
  templateUrl: './stepper-menu.component.html',
  styleUrls: ['./stepper-menu.component.scss']
})

export class StepperMenuComponent {
  @Input() clickPriority: boolean = false
  @Input() steps: Array<StepperMenu>
  @Input() clickable: boolean = true
  @Output() stepClick: EventEmitter<StepperMenu> = new EventEmitter()

  onStepClick(step: StepperMenu, event: MouseEvent) {
    if (this.clickPriority) {
      event.preventDefault()
      event.stopPropagation()
      this.stepClick.emit(step)
      return
    }
    this.stepClick.emit(step)
  }

}
