import { CampaignPluginName } from "../../../shared/models/campaign/campaign"
import { QuickSetupCouponBoxComponent } from "./components/coupon-box/quick-setup-coupon-box.component"
import { QuickSetupFortuneWheelComponent } from "./components/fortune-wheel/quick-setup-fortune-wheel.component"
import { QuickSetupNewsletterComponent } from "./components/newsletter/quick-setup-newsletter.component"
import { QuickSetupInputsComponent } from "./components/steps/inputs/quick-setup-inputs.component"
import { QuickSetupEndComponent } from "./components/steps/end/quick-setup-end.component"
import { QuickSetupStartComponent } from "./components/steps/start/quick-setup-start.component"
import { QuickSetupStep } from "./models/quick-setup.model"

const quickSetupSteps: QuickSetupStep = {
  path: '',
  component: QuickSetupStartComponent,
  children: [
    {
      path: CampaignPluginName.CouponBoxNotification,
      component: QuickSetupCouponBoxComponent,
      children: [
        {path: 'inputs', component: QuickSetupInputsComponent},
        {path: 'end', component: QuickSetupEndComponent},
      ]
    },
    { 
      path: CampaignPluginName.FortuneWheel,
      component: QuickSetupFortuneWheelComponent,
      children: [
        {path: 'inputs', component: QuickSetupInputsComponent},
        {path: 'end', component: QuickSetupEndComponent},
      ]
    },
    { 
      path:CampaignPluginName.NewsLetterNotification,
      component: QuickSetupNewsletterComponent,
      children: [
        {path: 'inputs', component: QuickSetupInputsComponent},
        {path: 'end', component: QuickSetupEndComponent},
      ]
    },
  ]
}

export default quickSetupSteps