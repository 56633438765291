import { EnvironmentName } from './models/environment-name.model'

export const environment = {
  name: EnvironmentName.DEVELOP,
  production: false,
  server_running: false,
  apiBaseURL: 'https://api-develop.one.store',
  widgetURL: 'https://cdn-develop.one.store/javascript/dist/1.0/jcr-widget.js',
  widgetPreviewURL: 'https://cdn-develop.one.store/javascript/dist/1.0/jcr-widget-preview.js',
  adminPortalBaseURL: 'https://admin-develop.one.store',
  hostname: 'app-develop.one.store',
  title: 'Proof Factor | Increase Your Conversion',
  shopify: {
    clientId: '',
    embedded: false
  },
  firebase: {
    apiKey: 'AIzaSyBe5Nc58qPkG1zoZyW5J_v2Ohop0qFJcKY',
    authDomain: 'proof-factor-develop.firebaseapp.com',
    databaseURL: 'https://proof-factor-develop.firebaseio.com',
    projectId: 'proof-factor-develop',
    storageBucket: 'cdn-develop.prooffactor.com',
    messagingSenderId: '689875958634',
    cdnUrl: 'cdn-develop.one.store',
  },
  stripe: {
    publishableKey: 'pk_test_8h4Ha4Fb7REVxWp5JtvW2GF1',
  },

  signUp: {
    autoGenerateCredentials: false,
    baseEmail: 'develop@prooffactor.com',
    basePass: '123456',
  },

  gettingStarted: {
    active: true,
    detectPollingInterval: 4000,
  },
  // fullStoryOrg: '17DNWX',
  logRocketID: 'morpheus/one-develop',
  googleAnaltyicsToken: 'UA-123323891-2',
  segmentApiKey: 'mpAQfvC7cPh1g4stReE2jLuc0QfnFleW',
  hCaptchaSiteKey: 'cd20bb80-c302-4982-a259-f753cf14e77d',
  jotFormApiKey: 'adec81eea78a10f70896bdbd5521d53f',
  shopifyEmbedPixelId: 'a99c662a-51a7-45d5-8e60-3301140e36b8'
}
