import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ConfigTriggerMonitoringService {
  private scrollSubj$ = new BehaviorSubject(0)

  constructor() { }


  set scrollNext(val) {
    this.scrollSubj$.next(val)
  }

  get scrollSubject() {
    return this.scrollSubj$
  }

}
