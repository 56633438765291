import { Component, inject, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import * as _ from 'lodash'
import { UserInfo } from '../../store/user/user.state'
import { combineLatest, Subscription } from 'rxjs'
import { GettingStartedService } from '../../core/services/getting-started.service'
import { GettingStartedGroupName } from '../../shared/models/getting-started/getting-started-group-name.model'
import { UserService } from '../../core/services/user.service'
import { environment } from '../../../environments/environment'
import { JFNewFormPresetV2, JFReturningFormPreset } from './pre-plans-survey.model'
import { JFQuestionType } from '../../shared/modules/jotform/models/jotform.model'
import { JotFormService } from '../../shared/modules/jotform/services/jotform.service'
import { ContactsResponse } from '../../pages/subscribers/models/subscribers-page.model'
import { keyValuePairKey } from '../../shared/models/key-value-pair.model'
import { filter, finalize, take } from 'rxjs/operators'
import { SegmentAnalyticsService } from '../../shared/services/segment-analytics.service'
import { PlatformOnboardingStage, PlatformOnboardingStatus } from '../../shared/models/platform-onboarding.model'
import { SafeLocalStorageService } from '../../core/services/safe-local-storage.service'
import { CustomSnackbarService } from '../../shared/modules/custom-snackbar/custom-snackbar.service'
import { ShopifyService } from '../../core/services/shopify.service'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
import { ChosePlanCompleteGuard } from '../../core/guards/chose-plan-complete.guard'
import { CartService } from '../../pages/cart/services/cart.service'

declare const $crisp: any

@Component({
  selector: 'pf-pre-plans-survey',
  templateUrl: './pre-plans-survey.component.html',
  styleUrls: ['./pre-plans-survey.component.scss'],
})
export class PrePlansSurveyComponent implements OnInit, OnDestroy {
  shopType: string
  userInfo: UserInfo
  subscription = new Subscription()
  loading: boolean = false
  userType = 'new'
  prePlansSurveyType =  GettingStartedGroupName.PrePlansSurveyNew
  isShopifyEmbedded = this.shopifyService.isEmbedded
  isFullscreen$ = this.shopifyService.fullScreen$
  isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)
  activeForm: any = JFNewFormPresetV2
  akc
  jotFormApi = window?.['JF']

  questionType = JFQuestionType
  supportedQuestionType = {
    [JFQuestionType.ControlCheckbox]: JFQuestionType.ControlCheckbox,
    [JFQuestionType.ControlNumber]: JFQuestionType.ControlNumber,
    [JFQuestionType.ControlTextarea]: JFQuestionType.ControlTextarea,
    [JFQuestionType.ControlImageChoice]: JFQuestionType.ControlImageChoice,
  }

  appCampaignIntentPrimaryId = '42'
  appCampaignIntentPrimaryAnswer = ''

  initialPaths = `/${RouteHeaderUrl.apps}`
  forbiddenPlans = inject(ChosePlanCompleteGuard).hideStripePlansFrom
  forceStripePricing = inject(CartService).forceStripePricing
  contacts: ContactsResponse = {} as ContactsResponse

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gettingStartedService: GettingStartedService,
    private safeLocalStorageService: SafeLocalStorageService,
    private userService: UserService,
    private shopifyService: ShopifyService,
    private jotFormService: JotFormService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private snackbarService: CustomSnackbarService,
  ) {
    this.loading = true
    this.userType =  _.get(this.route, 'snapshot.data.userType') || 'new'
    this.prePlansSurveyType =  this.userType === 'new' ? GettingStartedGroupName.PrePlansSurveyNew : GettingStartedGroupName.PrePlansSurveyReturning

    this.initialPaths = this.userType === 'new' ? '/onboarding/signup/new/apps/view/standard' : `/${RouteHeaderUrl.apps}`;

    this.gettingStartedService.setStatusGroupVisited(this.prePlansSurveyType)

    if (_.get(this.route.snapshot, 'queryParams.finished')) {
      this.saveSubmissionId(_.get(this.route.snapshot, 'queryParams.submission_id'))
      this.finishFormAndRedirect()
    }
  }

  ngOnInit() {
    if (this.userType === 'new') {
      this.activeForm = JFNewFormPresetV2
    } else {
      this.activeForm = JFReturningFormPreset
    }
    if (this.jotFormApi) {
      this.jotFormApi.initialize({ apiKey: environment.jotFormApiKey })

      this.jotFormApi.getFormProperties(this.activeForm.id, (res) => {
        const welcomePageActive = res.welcomePage && res.welcomePage[0] && res.welcomePage[0].isActive === '1'
        this.activeForm.welcomePage.active = welcomePageActive
        if (welcomePageActive) {
          this.activeForm.welcomePage = {
            ...this.activeForm.welcomePage,
            buttonText: res.welcomePage[0].buttonText,
            showQuestionCount: res.welcomePage[0].showQuestionCount === 'Yes',
            title: res.welcomePage[0].title,
            subTitle: res.welcomePage[0].subTitle
          }
        }
        // TODO: add processing of conditions if it becomes needed
        // this.activeForm.conditions = res.conditions

        this.jotFormApi.getFormQuestions(this.activeForm.id, (res) => {

          if (_.isEmpty(res)) {
            this.usePresetForm()
          } else {
            this.activeForm.questions = _.values(res).filter((q) => {
              if (q['name'] === 'prefilled' && this.activeForm.prefilled[1]) {
                this.activeForm.prefilled[1].qid = q.qid
              }
              if (q['name'] === 'prefilled2' && this.activeForm.prefilled[2]) {
                this.activeForm.prefilled[2].qid = q.qid
              }
              if (q['name'] === 'prefilled3' && this.activeForm.prefilled[3]) {
                this.activeForm.prefilled[3].qid = q.qid
              }
              if (q['name'] === 'prefilled4' && this.activeForm.prefilled[4]) {
                this.activeForm.prefilled[4].qid = q.qid
              }
              if (q['name'] === 'prefilled5' && this.activeForm.prefilled[5]) {
                this.activeForm.prefilled[5].qid = q.qid
              }
              if (q['name'] === 'prefilled6' && this.activeForm.prefilled[6]) {
                this.activeForm.prefilled[6].qid = q.qid
              }
              return q['hidden'] !== 'Yes' && _.values(this.supportedQuestionType).includes(q.type)
            })
            .sort((a, b) => a['order'] - b['order'])
            .map((q: any) => {
              const question: any = {
                qid: q.qid,
                type: q.type,
                text: q.text,
                description: q.description,
                required: q.required === 'Yes',
                hint: q.hint,
                active: false,
                error: false,
              }
              switch (question.type) {
                case JFQuestionType.ControlTextarea:
                  question.entryLimitMin = q.entryLimitMin ? (+q.entryLimitMin.split('-')[1] || 0) : 0
                  question.entryLimit = q.entryLimit ? (+q.entryLimit.split('-')[1] || 0) : 0
                  question.control = this.jotFormService.createTextareaControl(question)
                  break
                case JFQuestionType.ControlNumber:
                  question.maxValue = +q.maxValue || 0
                  question.minValue = +q.minValue || 0
                  question.control = this.jotFormService.createNumberControl(question)
                  break
                case JFQuestionType.ControlCheckbox:
                  question.otherText = q.allowOther === 'Yes' ? q.otherText : null
                  question.spreadCols = +q.spreadCols || 1
                  question.options = q.options.split('|').map(option => ({ value: option, checked: false }))
                  if (q.allowOther === 'Yes') {
                    question.options.push({ type: 'other', placeholder: q.otherText || 'Other...', value: '', checked: false})
                  }
                  break
                case JFQuestionType.ControlImageChoice:
                  question.options = JSON.parse(q.options).map(option => ({...option, checked: false}))
                  question.multiselect = q.multipleSelection === 'Yes'
              }
              return question
            })
          }
          if (this.activeForm.questions.length > 0) {
            this.activeForm.welcomePage.questionCountText = `${this.activeForm.questions.length} Questions`
          }

          // if (!welcomePageActive) {
            this.startQuestionnaire()
          // }
          this.loading = false
        }, (err) => {
          this.usePresetForm()
          console.error('JotForm', 'failed to fetch questions:', err)
        })
      }, (err) => {
        this.usePresetForm()
        console.error('JotForm', 'failed to load form properties:', err)
      })
    } else {
      this.usePresetForm()
      console.error('JotForm', 'not defined')
    }

    // FIXME: We need this data before doing anything else, make it available first
    this.subscription.add(
      combineLatest([
        this.userService.userInfo$.pipe(filter((user) => user !== null)),
        this.shopifyService.activeKnownCustomersCount$.pipe(filter((akcs) => akcs !== null), take(1)),
        this.shopifyService.contactsCount$.pipe(filter((contacts) => contacts !== null), take(1)),
        this.shopifyService.customersCount$.pipe(filter((customers) => customers !== null), take(1)),
        this.shopifyService.subscribersCount$.pipe(filter((subscribers) => subscribers !== null), take(1))
      ]).pipe(
        take(1),
      ).subscribe({
        next: ([user, akcs, contacts, customers, subscribers]) => {
          this.userInfo = _.cloneDeep(user)
          this.akc = akcs
          this.populatePrefilled(user, akcs, contacts, customers, subscribers)
          if (this.userType === 'new' && user?.platform_onboarding_status  !== PlatformOnboardingStatus.Completed) {
            this.subscription.add(this.userService.updatePlatformOnboarding({status: PlatformOnboardingStatus.Started, stage: PlatformOnboardingStage.Questionnaire}).subscribe())
            this.segmentAnalyticsService.identify(user.id, {
              onboarding_status: PlatformOnboardingStatus.Started,
              onboarding_stage: PlatformOnboardingStage.Questionnaire
            })
          }
        },
        error: err => {
          if ((err?.status === 429 || err?.status === 400) && err?.error?.message) {
            this.snackbarService.showError({ title: err.error?.title, text: err.error?.message })
          }
        }
      })
    )

    this.segmentAnalyticsService.track('Viewed Onboarding Survey', {
      form_id: this.activeForm.id
    })
    // this.segmentAnalyticsService.track('Onboarding | Platform | Welcome | Viewed')
  }

  populatePrefilled(userInfo: UserInfo, akcs: number, contacts: number, customers: number, subscribers: number) {
    // prefilled 1
    if (this.activeForm.prefilled?.[1]?.answers) {
      this.activeForm.prefilled[1].answers.field_1 = `${window.location.origin + window.location.pathname}`
      this.activeForm.prefilled[1].answers.field_2 = userInfo.id
      this.activeForm.prefilled[1].answers.field_8 = this.userType
      if (_.get(userInfo, 'shop.store_url')) {
        this.activeForm.prefilled[1].answers.field_4 = _.get(userInfo, 'shop.store_url')
      }
      if (_.get(userInfo, 'shop.profile.name')) {
        this.activeForm.prefilled[1].answers.field_5 = _.get(userInfo, 'shop.profile.name')
      }
      if (_.get(userInfo, 'shop.profile.plan_name')) {
        this.activeForm.prefilled[1].answers.field_6 = _.get(userInfo, 'shop.profile.plan_name')
      }
      if (_.get(navigator, 'language')) {
        this.activeForm.prefilled[1].answers.field_7 = _.get(navigator, 'language')
      }
      if (userInfo?.email) {
        this.activeForm.prefilled[1].answers.field_9 = userInfo.email
      }
    }

    // prefilled 2
    if (this.activeForm.prefilled?.[2]?.answers) {
      this.activeForm.prefilled[2].answers.field_1 = contacts
      this.activeForm.prefilled[2].answers.field_2 = customers
      this.activeForm.prefilled[2].answers.field_3 = subscribers

      if (userInfo?.shop_user?.shopify_shop_user?.first_name && userInfo?.shop_user?.shopify_shop_user?.last_name) {
        this.activeForm.prefilled[2].answers.field_5 = `${userInfo.shop_user.shopify_shop_user.first_name} ${userInfo.shop_user.shopify_shop_user.last_name}`
      }
      if (userInfo?.shop_user?.shopify_shop_user?.email) {
        this.activeForm.prefilled[2].answers.field_6 = userInfo.shop_user.shopify_shop_user.email
      }
    }

    // prefilled 3
    if (this.activeForm.prefilled?.[3]?.answers) {
      this.activeForm.prefilled[3].answers.field_9 = contacts
      this.activeForm.prefilled[3].answers.field_10 = customers
      this.activeForm.prefilled[3].answers.field_11 = subscribers
      this.activeForm.prefilled[3].answers.field_12 = akcs
    }

    // prefilled 4
    if (this.activeForm.prefilled?.[4]?.answers) {
      // If shop has less than 250 customers, skip phone verification and mark it as skipped_by_frontend
      const isShopifyShop = userInfo.shop?.type === 'ShopifyShop'
      if (isShopifyShop && akcs < 250) {
        this.activeForm.prefilled[4].answers.field_4 = 'False'
        this.activeForm.prefilled[4].answers.field_6 = 'skipped_by_frontend'
      }
    }
    // prefilled 4, for those who can get the shop_user.shopify_shop_user info
    if (this.activeForm.prefilled?.[4]?.answers && userInfo?.shop_user?.shopify_shop_user) {
      if (userInfo?.shop_user?.shopify_shop_user?.first_name && userInfo?.shop_user?.shopify_shop_user?.last_name) {
        this.activeForm.prefilled[4].answers.field_1 = `${userInfo.shop_user.shopify_shop_user.first_name} ${userInfo.shop_user.shopify_shop_user.last_name}`
      }
      if (userInfo?.shop_user?.shopify_shop_user?.email) {
        this.activeForm.prefilled[4].answers.field_2 = userInfo.shop_user.shopify_shop_user.email
      }
      if (userInfo?.shop_user?.shopify_shop_user?.phone) {
        this.activeForm.prefilled[4].answers.field_3 = userInfo.shop_user.shopify_shop_user.phone
      }
      if (userInfo?.shop_user?.shopify_shop_user?.sms_verified) {
        this.activeForm.prefilled[4].answers.field_4 = 'True'
      }
      // if (FullStoryService.getCurrentSessionURL()) {
        // this.activeForm.prefilled[4].answers.field_7 = FullStoryService.getCurrentSessionURL()
        this.activeForm.prefilled[4].answers.field_7 = 'N/A'
      // }
      if ($crisp.get('session:identifier')) {
        this.activeForm.prefilled[4].answers.field_8 = `https://app.crisp.chat/website/3bce23cc-1498-4691-9318-be6f0a4aa894/inbox/${$crisp.get('session:identifier')}`
      }
    }

    if (this.activeForm.prefilled[6]?.answers && this.userType === 'new' && userInfo.platform_onboarding_status !== PlatformOnboardingStatus.Completed) {
      this.activeForm.prefilled[6].answers.field_1 = PlatformOnboardingStatus.Started
      this.activeForm.prefilled[6].answers.field_2 = PlatformOnboardingStage.Questionnaire
      this.activeForm.prefilled[6].answers.field_3 = ''
      this.activeForm.prefilled[6].answers.field_4 = ''
      this.activeForm.prefilled[6].answers.field_5 = ''
      this.activeForm.prefilled[6].answers.field_6 = ''
    }

    this.contacts = {
      active_known_customers_total: akcs,
      contacts_total: contacts,
      customers_total: customers,
      subscribers_total: subscribers,
    }
  }

  usePresetForm() {
    this.activeForm.questions && this.activeForm.questions.forEach((question, i) => {
      switch (question.type) {
        case JFQuestionType.ControlTextarea:
          question.control = this.jotFormService.createTextareaControl(this.activeForm.questions[i])
          break
        case JFQuestionType.ControlNumber:
          question.control = this.jotFormService.createNumberControl(this.activeForm.questions[i])
          break
        case JFQuestionType.ControlCheckbox:
          break
      }
    })
    if (!this.activeForm.welcomePage.active) {
      this.startQuestionnaire()
    }
    this.loading = false
  }

  startQuestionnaire() {
    // Global Event
    this.segmentAnalyticsService.track('Platform | Questionnaire | Started')
    // Funnel Event: Onboarding Flow
    this.segmentAnalyticsService.track('Onboarding | Platform | Questionnaire | Started')
    this.activeForm.welcomePage.active = false
    if (this.activeForm.questions.length > 0) {
      this.activeForm.welcomePage.questionCountText = `${this.activeForm.questions.length} Questions`
      this.activeForm.questions[0].active = true
    } else {
      // if no questions finish form
      this.finishFormAndRedirect()
    }
  }

  processQuestion(index, isLast) {
    const question = this.activeForm.questions[index]
    if ([JFQuestionType.ControlCheckbox, JFQuestionType.ControlImageChoice, JFQuestionType.ControlRadio].includes(question.type)) {
      let checkboxAnswer = []
      switch(question.type) {
        case JFQuestionType.ControlImageChoice:
          checkboxAnswer = question.options.reduce((res, option) => {
            if (option.checked) {
              res.push(`${option.text}|${option.link}`)
              if (question.qid === this.appCampaignIntentPrimaryId) {
                this.appCampaignIntentPrimaryAnswer = option.text
                // store primary intent in localstorage to redirect user once they finish singup
                this.safeLocalStorageService.setItem('one-app-primary-intent', this.appCampaignIntentPrimaryAnswer)
              }
            }
            return res
          }, [])
          break
        case JFQuestionType.ControlCheckbox:
        case JFQuestionType.ControlRadio:
        default:
          checkboxAnswer = question.options.reduce((res, option) => {
            if (option.checked) {
              res.push(option.value || option.text || 'Other')
            }
            return res
          }, [])
      }

      this.activeForm.answers[question.qid] = checkboxAnswer
      question.error = question.required && checkboxAnswer?.length === 0

      if (!question.error) {
        this.nextQuestion(index, isLast)
      } else {
        this.activeForm.animation = 'bump'
        setTimeout(() => {
          this.activeForm.animation = 'none'
        }, 300)
      }
    } else {
      question.control.markAsTouched()

      if (question.control.errors === null) {
        this.activeForm.answers[question.qid] = question.control.value
        this.nextQuestion(index, isLast)
      } else {
        this.activeForm.animation = 'bump'
        setTimeout(() => {
          this.activeForm.animation = 'none'
        }, 300)
      }
    }
  }

  checkOption(event, option) {
    event.stopPropagation()
    if (option.type === 'other') {
      // if user clicked inside the input always set to true
      if (event.pointerType) {
        if (_.get(event, 'target.nodeName') === 'INPUT') {
          option.checked = true
        } else {
          option.checked = !option.checked
        }
      }
    } else {
      option.checked = !option.checked
    }
  }

  checkImage(event, option, question) {
    event.stopPropagation()
    if (question.multiselect) {
      option.checked = !option.checked
    } else {
      for (let o of question.options) {
        o.checked = o.text === option.text ? !option.checked : false
      }
    }
  }

  nextQuestion(index, isLast) {
    this.activeForm.animation = 'right'
    if (isLast) {
      this.finishQuestionnaire()
    } else {
      setTimeout(() => {
        this.activeForm.questions[index].active = false
        this.activeForm.questions[index + 1].active = true
      })
    }
  }

  prevQuestion(index) {
    this.activeForm.animation = 'left'
    setTimeout(() => {
      this.activeForm.questions[index].active = false
      this.activeForm.questions[index - 1].active = true
    })
  }

  finishQuestionnaire() {
    this.activeForm.submitting = true
    // NOTE: prefilled 4 will be populated on phone verification & prefilled 5 will be populated on plans page
    if (this.activeForm.prefilled?.[1]) { // original prefilled
      this.activeForm.answers[this.activeForm.prefilled[1].qid] = this.activeForm.prefilled[1].answers
    }
    if (this.activeForm.prefilled?.[2]) { // prefilled 2
      this.activeForm.answers[this.activeForm.prefilled[2].qid] = this.activeForm.prefilled[2].answers
    }
    if (this.activeForm.prefilled?.[3]) { // prefilled 3
      this.activeForm.answers[this.activeForm.prefilled[3].qid] = this.activeForm.prefilled[3].answers
    }
    if (this.activeForm.prefilled?.[4]) { // prefilled 4
      this.activeForm.answers[this.activeForm.prefilled[4].qid] = this.activeForm.prefilled[4].answers
    }
    if (this.activeForm.prefilled?.[6]) { // prefilled 6
      this.activeForm.answers[this.activeForm.prefilled[6].qid] = this.activeForm.prefilled[6].answers
    }
    this.shopUserDataUpdate()
    if (this.jotFormApi) {
      this.jotFormApi.createFormSubmissions(this.activeForm.id, JSON.stringify([this.activeForm.answers]), (res) => {
        if (_.has(res, '[0].submissionID')) {
          this.saveSubmissionId(_.get(res, '[0].submissionID'))
        } else {
          this.finishFormAndRedirect()
        }
        this.segmentAnalyticsService.track('Submitted Onboarding Survey', {
          answers: this.activeForm.answers
        })
        // Global Event
        this.segmentAnalyticsService.track('Platform | Questionnaire | Completed')
        // Funnel Event: Onboarding Flow
        this.segmentAnalyticsService.track('Onboarding | Platform | Questionnaire | Completed')
      }, (err) => {
        // if submit fails for some reason finish form so user doesn't get stuck here
        this.finishFormAndRedirect()
        console.error('JotForm', 'submit error:', err)
      })
    } else {
      // if jotform is undefined finish form so user doesn't get stuck here
      this.finishFormAndRedirect()
    }
  }

  saveSubmissionId(submissionId) {
    if (typeof $crisp !== 'undefined' && submissionId) {
      $crisp.push(['set', 'session:data', [`survey_${this.userType}_signup`, `https://www.jotform.com/edit/${submissionId}`]])
      const segmentPayload: any = {
        [`survey_${this.userType}_signup`]: `https://www.jotform.com/edit/${submissionId}`,
        onboarding_questionnaire_app_campaign_intent_primary: this.appCampaignIntentPrimaryAnswer,
      }
      if (this.userType === 'new' && this.userInfo?.platform_onboarding_status  !== PlatformOnboardingStatus.Completed) {
        segmentPayload['onboarding_status'] = PlatformOnboardingStatus.Started
        segmentPayload['onboarding_stage'] = PlatformOnboardingStage.Questionnaire
        segmentPayload['onboarding_active_profiles'] = this.contacts.active_known_customers_total
        segmentPayload['onboarding_active_contacts'] = this.contacts.contacts_total
        segmentPayload['onboarding_active_subscribers'] = this.contacts.subscribers_total
        segmentPayload['onboarding_active_customers'] = this.contacts.customers_total
      }
      this.segmentAnalyticsService.identify(this.userService.userInfo?.id, segmentPayload)
    }
    const pairs: any = {
      [keyValuePairKey.PrePlansSurveyNewSubmissionId]: submissionId
    }
    if (this.activeForm?.prefilled?.[4]?.qid) {
      pairs[keyValuePairKey.PrePlansSurveyNewPrefilled4] = this.activeForm.prefilled[4].qid
    }
    if (this.activeForm?.prefilled?.[5]?.qid) {
      pairs[keyValuePairKey.PrePlansSurveyNewPrefilled5] = this.activeForm.prefilled[5].qid
    }
    if (this.activeForm?.prefilled?.[6]?.qid) {
      pairs[keyValuePairKey.PrePlansSurveyNewPrefilled6] = this.activeForm.prefilled[6].qid
    }
    if (!_.isEmpty(pairs)) {
      this.subscription.add(this.gettingStartedService.updateKeyValuePairs(pairs).pipe(
        finalize(() => {
          this.finishFormAndRedirect()
        })
      ).subscribe())
    } else {
      this.finishFormAndRedirect()
    }
  }

  shopUserDataUpdate() {
    let payload = {}
    // If phone verification was skipped by frontend, update the backend
    if (this.activeForm.prefilled[4].answers.field_4 === 'False' && this.activeForm.prefilled[4].answers.field_6 === 'skipped_by_frontend') {
      payload = {sms_verified: false, sms_verified_reason: 'skipped_by_frontend'}
    }

    if (this.appCampaignIntentPrimaryAnswer && this.userInfo?.shop_user?.id) {
      payload = {...payload, app_campaign_intent_primary: this.appCampaignIntentPrimaryAnswer}
    }

    if (Object.keys(payload).length > 0) {
      this.subscription.add(this.userService.updateShopfiyShopUser(payload).subscribe())
    }
  }

  finishFormAndRedirect() {
    setTimeout(() => {
      this.theRedirect()
    }, 1000);
    this.gettingStartedService.completeStatusGroup(this.prePlansSurveyType)
    // if for some reason it's still on survey page after 5 secs redirect again
    setTimeout(() => {
      if (window?.location?.pathname?.includes('questionnaire')) {
        this.theRedirect()
      }
    }, 5000)
  }

  private theRedirect() {
    let url = this.initialPaths
    const canGoCustom = !this.forbiddenPlans.includes(this.userInfo?.shop?.profile?.plan_name)
    if (this.userService.isShopifyShop && this.akc > 5000 && (canGoCustom || this.forceStripePricing)) {
      url = `/onboarding/signup/${this.userType}/subscriptions/custom`
    }
    this.router.navigate([url]).then(() => {
      this.loading = false
    })
  }

  exitFullScreen(event) {
    event.preventDefault()
    this.shopifyService.disableFullScreen()
  }

  enterFullScreen(event) {
    event.preventDefault()
    this.shopifyService.enableFullScreen()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
