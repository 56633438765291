<h1 mat-dialog-title class="text-center">
  Limit Reached!
  <button class="mat-dialog-close" (click)="dialogRef.close()">
    <span>&times;</span>
  </button>
</h1>
<div mat-dialog-content class="text-center mx-2">
  <p>Your current plan has <b class="font-italic">{{limit}} Active Campaign Limit</b></p>
  <p>But good news, you have several options below.</p>
</div>
<div mat-dialog-actions class="mb-1 text-center d-block">
  <div class="CLR-Footer">
    <button class="pf-button filled md green" (click)="openCrisp()">
      <one-icon name="gift-outline" class="mr-1"></one-icon> Request Gift</button>
    <button class="pf-button filled md blue" (click)="upgrade()">
      <one-icon name="arrow-up" class="mr-2"></one-icon>Upgrade Plan</button>
    <button class="pf-button filled md alt-green" (click)="replace()">
      <one-icon name="repeat" class="mr-2"></one-icon>Replace Existing Campaign</button>
  </div>
</div>
