export const IframeEventName = 'PFWidgetEvent'

export enum IframeEventType {
  PFTriggerButtonClick = 'PFTriggerButtonClick',
  PFTimerStart = 'PFTimerStart',
  PFTimerEnd = 'PFTimerEnd',
  PFTimerCancel = 'PFTimerCancel',
  PFLeaveIntent = 'PFLeaveIntent',
  PFLeaveIntentInit = 'PFLeaveIntentInit',
  PFLeaveIntentInited = 'PFLeaveIntentInited',
  PFShowAfter = 'PFShowAfter',
  PFShowAfterInit = 'PFShowAfterInit',
  PFShowAfterInited = 'PFShowAfterInited',
  PFInactivity = 'PFInactivity',
  PFInactivityInit = 'PFInactivityInit',
  PFInactivityInited = 'PFInactivityInited',

  IncomingResetTriggers = 'IncomingPFResetTriggers',
  IncomingStartTriggers = 'IncomingPFStartTriggers',
  IncomingStopTriggers = 'IncomingPFStopTriggers',
}

export interface TriggersConsoleItem {
  text: string
  class: string
  type: IframeEventType
  animatedTimer?: number
}
