<!-- https://github.com/ProofFactorLLC/proof-factor-widget -->
<div class="newsletter-preview-container conveythis-no-translate" [class._flexed]="flexContainer">
  <div *ngIf="withBrowser" class="Browser-Header" [style.width]="sizes && sizes.width"
       [class._mobile-header]="previewService.previewDevice.isMobile || false">
    <div class="arrow"></div>
  </div>
  <iframe
    [attr.src]="!iFrameSRC ? null : iFrameSRC | safe"
    (load)="onLoad()"
    #iFrame
    [ngStyle]="sizes"
    [ngClass]="previewClass"
    frameborder="0"
    [scrolling]="scrolling"
    hspace="0"
    vspace="0"
    marginheight="0"
    marginwidth="0"
  ></iframe>
</div>
<div *ngIf="notLoaded" class="text-muted small text-center">
  Preview is not available
  <div *ngIf="!production">(most likely widget can't be loaded)</div>
</div>
