
import { Injectable } from '@angular/core'
import moment from 'moment'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { PricingUpdatesModalComponent } from '../../shared/components/modals/pricing-updates.modal/pricing-updates.modal.component'
import { UpgradePlanModalComponent } from '../../shared/components/modals/upgrade-plan.modal/upgrade-plan.modal.component'
import { ActiveAppCampaignService } from './active-app-campaign.service'
import { ShopifyService } from './shopify.service'
import { UserService } from './user.service'
import { MatDialog } from '@angular/material/dialog'

export enum UpgradeModalType {
  PricingUpdateModal = 'PricingUpdateModal',
  PlanUpgradeModal = 'PlanUpgradeModal',
}

@Injectable()
export class UpgradePlanHelperService {
  public showUpgradeModalOnEdit$ = new BehaviorSubject<UpgradeModalType|boolean>(null)
  public showUpgradeModalOnLogin$ = new BehaviorSubject<boolean>(null)
  public showRequestPriceModal$ = new BehaviorSubject<boolean>(null)

  constructor(
    private shopifyService: ShopifyService,
    private userService: UserService,
    private dialog: MatDialog,
    private activeAppCampaignService: ActiveAppCampaignService,
  ) {
    const userInfo = this.userService.userInfo
    const showRequestPriceModal = userInfo?.custom_plan_trial && !userInfo?.custom_plan_invoice && !userInfo?.custom_plan_invoice_paid
    this.showRequestPriceModal$.next(showRequestPriceModal)
    combineLatest([
      this.shopifyService.activeKnownCustomersCount$.pipe(filter(next => next !== null), take(1)),
      this.activeAppCampaignService.activeCampaignCount$.pipe(filter(next => next !== null), take(1)),
      this.shopifyService.highEmailActivity$.pipe(filter(next => next !== null), take(1))
    ])
    .subscribe(([customers, campaigns, highEmailActivity]) => {
      // show popup for shopify budding users that are not on usage plan on login/refresh if they are over limit
      if (userInfo?.shop?.type === 'ShopifyShop') {
        // bring whale edit enforcement in line with backend
        if (
          (this.userService.isLegacyBuddingPlanUser || this.userService.isNewFreePlanUser)
              && (highEmailActivity || !!this.userService.pricingModalDynamicFlow?.third_coupon_deadline)
        ) {
          this.showPricingUpdateModal()
        } else if (this.userService.isNewPlanUser && userInfo?.subscription?.campaigns_limit?.limit < campaigns && customers < 6501) {
          this.showUpgradeModalOnEdit$.next(UpgradeModalType.PlanUpgradeModal)
        } else if (this.userService.isLegacyBuddingPlanUser // only enforce for budding for now
                    && !userInfo.shop.usage_charge_active // don't show for users on usage based plans
                    && customers > 50 // don't enforce for small shops
                    && (customers < 6501 || this.userService.userSentLargeEmailBroadcast || !!this.userService.pricingModalDynamicFlow?.third_coupon_deadline) // only enforce for whales w/ dynamic flow or who send large email broadcasts
                    && this.userService.userHasSeenPricingModal // for users that saw the modal
                    && !userInfo?.custom_plan_trial // don't show for users on a custom plan trial
        ) {
          this.showPricingUpdateModal()
        } else {
          this.showUpgradeModalOnEdit$.next(false)
          this.showUpgradeModalOnLogin$.next(false)
        }
      } else {
        this.showUpgradeModalOnEdit$.next(false)
        this.showUpgradeModalOnLogin$.next(false)
      }
    })
  }

  private showPricingUpdateModal() {
    // saw original modal
    if (!this.userService.firstSeenPricingData?.extended) {
      this.showUpgradeModalOnEdit$.next(UpgradeModalType.PricingUpdateModal)
      this.showUpgradeModalOnLogin$.next(true)
    } else {
      // for those who saw extended deadline check if it has passed
      const now = moment()
      const daysLeft = moment(this.userService.firstSeenPricingData?.third_deadline).startOf('day').diff(now.startOf('day'), 'days')
      if (daysLeft < 0) {
        this.showUpgradeModalOnEdit$.next(UpgradeModalType.PricingUpdateModal)
        this.showUpgradeModalOnLogin$.next(true) // show on login even if deadline has not passed
      } else {
        this.showUpgradeModalOnEdit$.next(false)
        this.showUpgradeModalOnLogin$.next(true)
      }
    }
  }

  editingNotAllowed() {
    if (this.showUpgradeModalOnEdit$.value) {
      switch (this.showUpgradeModalOnEdit$.value) {
        case UpgradeModalType.PricingUpdateModal:
          // this.dialog.open(PricingUpdatesModalComponent, {
          //   width: '560px',
          //   disableClose: true,
          //   data: {}
          // })
          break
        case UpgradeModalType.PlanUpgradeModal:
        default:
          this.dialog.open(UpgradePlanModalComponent, { data: { text: `You've exceeded active campaign limit. Please upgrade your plan.`}})
      }
      return true
    }
    return false
  }
}
