export enum SMSOptInLevel {
  DoubleOptIn = 'double_optin',
  SingleOptIn = 'single_optin',
  AnyOptIn = 'any_optin'
}

export enum SMSListName {
  AllSubscribers = 'All Subscribers | on Shopify & ONE',
  OnlySubscribers = 'Only Subscribers | on ONE'
}

export enum SMSPhoneNumberVerificationStatus {
  Denied = 'denied',
  Pending = 'pending',
  Verified = 'verified'
}
