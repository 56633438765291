import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import _ from 'lodash'

@Component({
  templateUrl: './scholarship-application.modal.component.html',
  styleUrls: ['./scholarship-application.modal.component.scss'],
})

export class ScholarshipApplicationModalComponent {
  planName: string
  brandingName: string
  infoNote = 'We will text or call you within \<b>1 to 5 business days\</b> regarding your application status, meanwhile use our app for free'
  constructor(
    public dialogRef: MatDialogRef<ScholarshipApplicationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data) {
      this.planName = this.data.planName
      this.brandingName = this.data.brandingName
    }
  }
}
