<h3 class="ImageUpload-Title">
  Upload Image
  <div class="ImageUpload-Close" (click)="dialogRef.close()">&times;</div>
</h3>
<div class="ImageUpload">
  <div>
    <pf-image-upload
      [path]="data.path"
      class="ImageUpload-Loader"
      (imageTypeError)="imageTypeError = $event"
      (selectedImage)="setImage($event)"
    ></pf-image-upload>
    <small *ngIf="data?.type === 'unlayer' && !imageTypeError" class="d-block text-muted">
      Use .jpg, .gif, or .png file formats for optimal results.
    </small>
    <div *ngIf="imageTypeError" class="invalid-feedback d-block">
      The file type is not recognized, please use gif, jpeg/jpg, png, svg and webp format
    </div>
  </div>

  <pf-image-upload-lib
    class="ImageUpload-Lib"
    [path]="data.path"
    (selectedImage)="setImage($event)"
  ></pf-image-upload-lib>
</div>

<div class="ImageUpload-Buttons mt-4">
  <button class="pf-button outline gray" (click)="dialogRef.close()">Cancel</button>
  <button class="pf-button filled green ml-3" (click)="apply()">Apply Image</button>
</div>


<pf-loading-overlay
  [isFixed]="false"
  [filter]="{only: ['uploadFile']}"
></pf-loading-overlay>
