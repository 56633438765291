import { GiftCardOnboardingApiService } from './../../gift-card-onboarding/services/gift-card-onboarding-api.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import _ from 'lodash'
import { forkJoin, Subscription } from 'rxjs'
import { UserService } from '../../../../core/services/user.service'
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { StoreState } from '../../../../store/store.state'
import { SegmentAnalyticsService } from '../../../services/segment-analytics.service'
import { SMSOnboardingStepName } from '../../sms-onboarding/models/sms-onboarding.model'
import { SMSOnboardingApiService } from '../../sms-onboarding/services/sms-onboarding-api.service'
import { CampaignCard, CampaignCardTag, CampaignCardValues } from '../models/campaign-cards.model'
import { CampaignCardsHelperService } from '../services/campaign-cards-helper.service'
import { CardQuickstartModalComponent } from './modals/card-quickstart-modal/card-quickstart-modal.component'
import { CampaignLimitReachedModalComponent } from '../../../components/modals/campaign-limit-reached.modal/campaign-limit-reached.modal.component'
import { BrandingService } from '../../../../core/services/branding.service'
import { GettingStartedService } from '../../../../core/services/getting-started.service'
import { ScholarshipApplicationModalComponent } from './modals/scholarship-application.modal/scholarship-application.modal.component'
import { ScholarshipApplicationStatusEnum } from '../../../models/getting-started/getting-started-status.model'

import { EmailAutomationTypeEnum } from '../../../../pages/emails/components/email-automations/enums/email-automation-type.enum'
import { BroadcastNewsletterEmailType } from '../../../../pages/email-builder/models/email-editor.models'
import { GiftCardOnboardingStepName, GiftCardOnboardinRouterState } from '../../gift-card-onboarding/models/giftcard-onboarding.model'
import { CardNewModalComponent } from './modals/card-new-modal/card-new-modal.component'
import { PluginLockedModalComponent } from '../../../components/modals/plugin-locked.modal/plugin-locked.modal.component'
import { RedirectService } from '../../../../core/services/redirect.service'
import { GettingStartedGroupName } from '../../../models/getting-started/getting-started-group-name.model'
import { UpsellService } from '../../../../pages/upsell/services/upsell.service'
import { finalize } from 'rxjs/operators'
import { RouteHeaderUrl } from '../../../components/one-header/header-navigation.model'
import { FeatureTypeModal, VoteFeatureModalComponent } from '../../../components/modals/vote-feature.modal/vote-feature.modal.component'
import { ReceiveModalType, ReceiveRequestModalComponent } from '../../../components/modals/receive-request.modal/receive-request.modal.component'
import { ApiService } from '../../../../core/services/api/api.service'
import { upsellAccessFormPreset } from '../../jotform/models/upsell-access-form-preset.model'
import { AccessFormModalComponent } from '../../jotform/components/access-form-modal/access-form-modal.component'
import { ShopifyService } from '../../../../core/services/shopify.service'
import { CrispSessionEvent } from '../../../models/user/crisp-user.model';
import { UpgradePlanModalComponent } from '../../../components/modals/upgrade-plan.modal/upgrade-plan.modal.component';
import { ActiveAppCampaigns } from '../../../models/app-campaign-type.model';
import { CampaignService } from '../../../../core/services/campaign.service';
import { Campaign } from '../../../models/campaign/campaign';
import { VideoTutorialModalComponent } from '../../../../pages/tutorials/standalone/modal/video-tutorial-modal.component';
import { TutorialsService } from '../../../../pages/tutorials/services/tutorials.service';
import { CrispService } from '../../../../core/services/crisp.service';
import { CustomSnackbarService } from '../../custom-snackbar/custom-snackbar.service';
import { UserShopType } from '../../../models/user/user-shop-type.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'pf-campaign-cards',
  templateUrl: './campaign-cards.component.html',
  styleUrls: ['./campaign-cards.component.scss'],
})

export class CampaignCardsComponent implements OnInit, OnDestroy {
  @Input() isOnboarding: boolean = false
  campaignName: string
  campaignCards: CampaignCard[] = []
  shopifyCampaignCards: CampaignCard[] = []
  campaignCardTags: CampaignCardTag[] = []
  get selectedTags() {
    return this.campaignCardTags.filter((t) => t.selected)
  }

  subscription$ = new Subscription()

  sampleOrder: any = null

  brandingName: string = null

  giftCardViewMode = false

  routeHeaderUrl = RouteHeaderUrl
  contactSupportTimeout = null
  emailComplianceOfficer = _.get(this.route, 'snapshot.data.emailComplianceOfficer', null)

  isShopify = false
  isShopifyBudding = false
  activeCampaignCount = 0

  onboardingView: 'default' | 'standard' = 'default'
  showOnboardingView: boolean = false
  public isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)
  private segmentEventNames = {
    [CampaignCardValues.UpsellCard]: 'Clicked Card - Post Purchase Upsell',
    [CampaignCardValues.RecoveryCard]: 'Clicked Card - Checkout Abandonment',
    [CampaignCardValues.CaptureLeads]: 'Clicked Card - Opt in Popups',
    [CampaignCardValues.EmailAutomation]: 'Clicked Card - Email Marketing',
    [CampaignCardValues.SMSAutomation]: 'Clicked Card - SMS Marketing',
    [CampaignCardValues.SocialProof]: 'Clicked Card - Social Proof',
    [CampaignCardValues.Gamification]: 'Clicked Card - Gamification',
    [CampaignCardValues.ThankYou]: 'Clicked Card - Thank You',
    [CampaignCardValues.Welcome]: 'Clicked Card - Welcome',
    [CampaignCardValues.Discounts]: 'Clicked Card - Discount Codes',
    [CampaignCardValues.RecommendationsCard]: 'Clicked Card - Product Recommendations',
    [CampaignCardValues.BirthdayCard]: 'Clicked Card - Birthday',
    [CampaignCardValues.WinbackCard]: 'Clicked Card - Winback/Loyalty',
    [CampaignCardValues.GiftCards]: 'Clicked Card - Gift Card Codes',
    [CampaignCardValues.Announcement]: 'Clicked Card - Announcement Pop ups',
    [CampaignCardValues.ChatGPT]: 'Clicked Card - ChatGPT',
  }

  constructor(
    private helperService: CampaignCardsHelperService,
    private giftCardOnboardingApiService: GiftCardOnboardingApiService,
    private smsOnboardingApiService: SMSOnboardingApiService,
    private crispService: CrispService,
    private router: Router,
    private userService: UserService,
    private store: Store<StoreState>,
    public dialog: MatDialog,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private gettingStartedService: GettingStartedService,
    private brandingService: BrandingService,
    private redirectService: RedirectService,
    private shopifyService: ShopifyService,
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private upsellService: UpsellService,
    private tutorialsService: TutorialsService,
    private apiService: ApiService,
    private snackbarService: CustomSnackbarService,
  ) {
    this.brandingName = this.brandingService.getBrandingData().name
    this.isOnboarding = this.isOnboarding || this.route.snapshot.queryParams?.onboarding || _.get(this.route, 'snapshot.data.onboarding', false)
    if (this.isOnboarding) {
      this.gettingStartedService.setStatusGroupVisited(GettingStartedGroupName.Apps)
    }
    this.onboardingView = _.get(this.route, 'snapshot.params.view', 'default')
    this.showOnboardingView = this.isOnboarding && this.onboardingView !== 'standard'
  }

  ngOnInit(): void {
    this.segmentAnalyticsService.track('Viewed Campaign Cards', {
      state: {
        onboarding: this.isOnboarding,
        onboardingView: this.onboardingView,
        step: 'view_page',
        group: 'campaign_cards',
      },
    })

    this.campaignName = this.isOnboarding ? 'My First Campaign' : 'My Campaign'

    this.campaignCards = this.helperService.campaignCards

    this._updateGiftCardEligibility()

    this.subscription$.add(
      // Added this.userService.getUserSubscription() to trigger Store refresh of userInfo, otherwise in some cases it doesn't have all required information
      forkJoin([
        this.smsOnboardingApiService.getComplianceOfficer(),
        this.userService.getUserSubscription(),
      ]).subscribe(([complianceOfficer, subscription]) => {
        const smsOnboarded = _.get(complianceOfficer, 'completed', false) || _.get(complianceOfficer, 'single_opt_in_terms_completed', false)
        this._updateSMSEligibility(this.helperService.userSMSEligible, smsOnboarded)
        this._updatePremiumEligibility(this.helperService.userPremiumEligible)
        this.activeCampaignCount = subscription?.campaigns_limit?.active || 0
        this.isShopify = this.userService?.userInfo?.shop?.type === 'ShopifyShop'
        this.filterCampaignCards()
        this.isShopifyBudding = this.isShopify && subscription?.plan?.payment_gateway_plan_identifier === 'budding'
      }))

    this._pollPreviewOrder()

    // If user has pending scholarship application and hasn't seen the popup yet display congrats popup
    const scholarship = this.gettingStartedService.getScholarshipStatus()
    if (_.get(scholarship, 'application.status') === ScholarshipApplicationStatusEnum.Pending
        && !_.get(scholarship, 'popup_seen', false)) {
      this.showScholarshipPopup(_.get(scholarship, 'plan_name', false))
    }

    if (this.isOnboarding) {
      this.contactSupportTimeout = setTimeout(this.contactSupport, 60000)
      this.openTutorialModal( 'Show Overview Video')
    }

    // this._updateEmailEligibility(this.emailComplianceOfficer?.completed)
  }

  filterCampaignCards() {
    const filterCards = (cards, filterFn) =>
    cards.map(card => ({
      ...card,
      children: card.children.filter(filterFn)
    })).filter(card => card.children.length > 0 || filterFn(card))
    const campaignCardsCopy = _.cloneDeep(this.campaignCards)
    if (!this.isShopify) {
      const isNotShopify = card => !card.shopTypes || !card.shopTypes.includes(UserShopType.ShopifyShop)
      const isShopify = card => !card.shopTypes || card.shopTypes && card.shopTypes.includes(UserShopType.ShopifyShop)
      this.campaignCards = filterCards(campaignCardsCopy, isNotShopify)
      this.shopifyCampaignCards = filterCards(campaignCardsCopy, isShopify)
      // Add (Other) to duplicate titles
      const campaignCardTitles = new Set(this.campaignCards.map(card => card.title));
      this.shopifyCampaignCards.forEach(card => {
        if (campaignCardTitles.has(card.title)) {
            card.title += ' (Other)';
        }
      });
    } else {
      const isNotCustomShop = card => !card.shopTypes || !card.shopTypes.includes(UserShopType.CustomShop)
      this.campaignCards = filterCards(campaignCardsCopy, isNotCustomShop)
    }
  }

  showScholarshipPopup(planName) {
    const dialogRef = this.dialog.open(ScholarshipApplicationModalComponent, {
      width: '500px',
      data: {
        planName,
        brandingName: this.brandingName
      }
    })
    this.subscription$.add(dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.subscription$.add(this.gettingStartedService.updateScholarshipStatus({ popup_seen: true }).subscribe())
      }
    }))
  }

  public createKeyword(e) {
    const keyword = e.target.value
    e.target.value = ''
    if (keyword.length === 0) {
      return
    }
    const exists = this.campaignCardTags.find((t) => t.label.toLowerCase() === keyword.toLowerCase())
    if (exists) {
      if (!exists.selected) {
        this.selectTag(exists)
      }
      return
    }

    this.segmentAnalyticsService.track('Campaign Cards Keyword Created', {
      metadata: {
        keyword: keyword
      }
    })

    const newTag = {
      label: keyword,
      selected: false
    }
    this.campaignCardTags.unshift(newTag)
    this.selectTag(newTag)
  }

  public selectTag(tag: CampaignCardTag) {
    tag.selected = !tag.selected
    if (tag.selected) {
      this.segmentAnalyticsService.track('Campaign Cards Keyword Selected', {
        metadata: {
          keyword: tag.label
        }
      })
    }
    this._refreshFilters()
  }

  public openCard(card: CampaignCard, viewOnly = false) {
    this.segmentAnalyticsService.track('Clicked App Card')
    if (this.segmentEventNames[card.value]) {
      this.segmentAnalyticsService.track(this.segmentEventNames[card.value], {
        state: {
          onboarding: this.isOnboarding,
          onboardingView: this.onboardingView,
          step: 'clicked_card',
          group: 'app_cards',
        },
        metadata: {
          card
        }
      })
    }

    // assign viewOnly to card
    card.viewOnly = viewOnly

    const urlToOpen = card?.openUrl
    // FIXME: in the future when we have more ChatGPT features available, upon clicking on the card we’ll show a modal to pick from. to be consistent with the other card behaviors
    if (urlToOpen && urlToOpen.length > 0) {
      this.router.navigate([urlToOpen])
    } else {
      const dialogRef = this.dialog.open(CardNewModalComponent, {
        width: '685px',
        maxWidth: this.isMobile ? '95vw' : '80vw',
        data: card
      })
      this.subscription$.add(dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.onGetStartedClicked(result)
        }
      }))
    }
  }

  private contactSupport() {
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['set', 'session:event', [CrispSessionEvent.Resolve_First_App_Convos]])
    }
  }

  public onGetStartedClicked(card: CampaignCard) {
    // PF-4602 - Aggregate Activity is under maintenance
    if (card.value === CampaignCardValues.AggregateSalesPop) {
      this.snackbarService.showError({title:'Under Maintenance' , text: 'Sorry for the inconvenience, we are performing maintenance on this feature.'})
      return
    }
    this.segmentAnalyticsService.track('Clicked Create Card', {
      state: {
        onboarding: this.isOnboarding,
        onboardingView: this.onboardingView,
        step: 'clicked_card',
        group: 'campaign_cards',
      },
      metadata: {
        card
      }
    })
    ///
    // if over limit no quickstart
    if (card.quickstart && !card.locked) {
      const popups = [
        CampaignCardValues.CouponBoxNotification,
        CampaignCardValues.FortuneWheel,
        CampaignCardValues.NewsLetterNotification,
      ]
      if (popups.includes(card.value as any)) {
        this._openNewQuickstartModal(card)
      } else {
        this._openOldQuickstartModal(card)
      }
    } else {
      this._getStarted(card)
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe()
  }

  private _pollPreviewOrder() {
    this.subscription$.add(this.helperService.getPreviewOrder()
      .subscribe((res) => {
        if (res) {
          this.sampleOrder = res
          this._updateSocialQuickstartEligibility(true)
        } else {
          this._updateSocialQuickstartEligibility(false)
          setTimeout(() => this._pollPreviewOrder(), 3000)
        }
      }))
  }

  // New quick setup for popups
  private _openNewQuickstartModal(card: CampaignCard) {
    this.store.dispatch(new ShowLoading(card.value))
    this.subscription$.add(
      this.campaignService.createCampaign({ name:'My campaign'} as Campaign).subscribe((campaign) => {
        const queryParams: any = {
          showQuickSetupModal: card.value,
        }
        this.trackCreateCampaignEvent(card.value)
        const url = `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${campaign.id}/${RouteHeaderUrl.apps}`
        this.router.navigate([url], {
          queryParams
        }).then(() => {
          this.store.dispatch(new HideLoading(card.value))
        })
      })
    )
  }

  // old quick setup for other plugins
  private _openOldQuickstartModal(card: CampaignCard) {
    const dialogRef = this.dialog.open(CardQuickstartModalComponent, {
      width: '820px',
      data: {
        card,
        order: this.sampleOrder
      },
    })
    this.subscription$.add(dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result === 'quickstart') {
          // TODO: we need to remove UpgradePlanModalComponent in other places since we let backend control it in http.error.interceptor for now
          // if (this.userService?.userInfo?.subscription?.campaigns_limit?.is_over_limit) {
          //   this.dialog.open(UpgradePlanModalComponent, { data: { type: ActiveAppCampaigns.Popup }})
          //   return
          // }
          if (this.helperService.userOverCampaignLimit) {
            this._showLimitModal(card)
            return
          }
          this._getStarted(card, true)
        } else {
          this._getStarted(card)
        }
      }
    }))
  }

  private _getStarted(card: CampaignCard, quickstart: boolean = false) {
    this._userChoseCard()
    const value = card.locked ? card.locked_value : card.value
    switch (value) {
      case CampaignCardValues.NeedsUpgrade:
        this._openUpgradeModal(card)
        break
      case CampaignCardValues.OpenCrisp:
        this.openCrisp(card)
        break
      case CampaignCardValues.GiftCardsOnBoarding:
        this._openGiftCardOnboarding(this.giftCardViewMode)
        break
      case CampaignCardValues.TCPACompliance:
        this._openSMSOnboarding()
        break
      // case CampaignCardValues.EmailOnboarding:
      //   this._openEmailOnboarding()
      //   break
      case CampaignCardValues.CollectPhoneNumbers:
        this._openSMSOnboarding(SMSOnboardingStepName.collect_numbers)
        break
      case CampaignCardValues.BirthdayEmails:
        this.navigateToEmailEditor(EmailAutomationTypeEnum.Birthday, card.value)
        break
      case CampaignCardValues.BroadcastCoupon:
        this.navigateToEmailEditor(BroadcastNewsletterEmailType.broadcastCoupon, card.value)
        break
      case CampaignCardValues.BroadcastNewsletter:
        this.navigateToEmailEditor(BroadcastNewsletterEmailType.broadcastNewsletter, card.value)
        break
      case CampaignCardValues.UpsellCheckout:
        this.createPostPurchaseUpsell(card.value)
        break
      case CampaignCardValues.BroadcastEmails:
      case CampaignCardValues.UpsellEmails:
      case CampaignCardValues.WinbackEmails:
      case CampaignCardValues.CartRecoveryEmails:
      case CampaignCardValues.WelcomeNewAccountsCard:
      case CampaignCardValues.WelcomeNewCustomerCard:
      case CampaignCardValues.WelcomeNewSubscriberCard:
      case CampaignCardValues.ThankYou:
      case CampaignCardValues.SMSAutomation:
      case CampaignCardValues.SMSUpsell:
      case CampaignCardValues.SMSWinback:
      case CampaignCardValues.SMSBirthday:
      case CampaignCardValues.SMSCartRecovery:
      case CampaignCardValues.SMSBroadcastCoupon:
      case CampaignCardValues.SMSBroadcastNoCoupon:
      case CampaignCardValues.FixedAmount:
      case CampaignCardValues.Percentage:
      case CampaignCardValues.GenericCode:
      case CampaignCardValues.ManualMaster:
      case CampaignCardValues.FreeShipping:
      case CampaignCardValues.BuyXGetY:
      case CampaignCardValues.GiftCardsUnique:
        this.store.dispatch(new ShowLoading(card.value))
        this.subscription$.add(this.helperService.getSkipCampaignObservable(this.isOnboarding)
          .subscribe(() => {
            const redirectUrl = this.helperService.getRedirectUrl(card.value)
            this.router.navigateByUrl(redirectUrl).then(() => {
              this.store.dispatch(new HideLoading(card.value))
            })
          }))
        break
      default:
        // if (this.isShopifyBudding && this.activeCampaignCount > 0) {
        //   const dialogRef = this.dialog.open(BuddingActiveCampaignWarningModalComponent, {
        //     width: '600px',
        //     data: { quiet: true }
        //   })
        //
        //   this.subscription$.add(dialogRef.afterClosed().subscribe((res) => {
        //     if (res) {
        //       this.createCampaign(card.value, quickstart)
        //     }
        //   }))
        // } else {
        //   this.createCampaign(card.value, quickstart)
        // }
        this.createCampaign(card.value, quickstart) // remove when BuddingActiveCampaignWarningModalComponent should be live again
    }
  }

  private createCampaign(cardValue, quickstart) {
    this.store.dispatch(new ShowLoading(cardValue))
    this.subscription$.add(this.helperService.getCreateCampaignObservable(this.campaignName, cardValue, false, quickstart)
      .subscribe((redirectUrl) => {
        const queryParams: any = {}
        this.trackCreateCampaignEvent(cardValue)
        if (this.isOnboarding && quickstart) {
          queryParams.firstCampaignLaunched = true
        }
        this.router.navigate([redirectUrl], {
          queryParams
        }).then(() => {
          this.store.dispatch(new HideLoading(cardValue))
        })
      }))
  }

  private trackCreateCampaignEvent(cardValue: string) {
    this.segmentAnalyticsService.track('Create Campaign', {
      state: {
        onboarding: this.isOnboarding,
        onboardingView: this.onboardingView,
      },
      metadata: {
        plugin: cardValue
      },
    })
  }

  private _userChoseCard() {
    if (this.isOnboarding) {
      this.gettingStartedService.completeStatusGroup(GettingStartedGroupName.Apps)
      if (this.contactSupportTimeout) {
        clearTimeout(this.contactSupportTimeout)
      }
    }
  }
  private _openUpgradeModal(card) {
    this.segmentAnalyticsService.track('Clicked Pro Feature', {
      state: {
        onboarding: this.isOnboarding,
        onboardingView: this.onboardingView,
        step: 'plugins',
        group: 'campaign',
      },
      metadata: {
        feature: card.value,
      },
    })
    this.segmentAnalyticsService.track('Upgrade Request', {
      state: {
        onboarding: this.isOnboarding,
        onboardingView: this.onboardingView,
        step: 'plugins',
        group: 'campaign',
      },
      metadata: {
        feature: card.value,
      },
    })
    const upgradeDialog = this.dialog.open(PluginLockedModalComponent, {
      width: '530px',
      data: {
        userInfo: this.userService.userInfo,
      },
    })
    this.subscription$.add(upgradeDialog.afterClosed().subscribe((dialogData) => {
      if (_.get(dialogData, 'purchase', false)) {
        this.segmentAnalyticsService.track('Upgrade Review', {
          state: {
            onboarding: this.isOnboarding,
            onboardingView: this.onboardingView,
            step: 'plugins',
            group: 'campaign',
          },
          metadata: {
            feature: card.value,
          },
        })
        this._upgradePlanClicked(card)
      }
    }))
  }


  private _upgradePlanClicked(card) {
    this.redirectService.setTemporaryRoute(this.router.url)
    if (this.isShopify) {
      this.router.navigateByUrl(`/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}`)
    } else {
      this.router.navigateByUrl(`/${RouteHeaderUrl.choose_plan}`)
    }
  }

  private navigateToEmailEditor(type, cardValue) {
    this.store.dispatch(new ShowLoading(cardValue))
    this.subscription$.add(this.helperService.getSkipCampaignObservable(this.isOnboarding)
      .subscribe(() => {
        const queryParams: any = {}
        if (type !== EmailAutomationTypeEnum.Birthday) {
          queryParams.type = type
        }
        const redirectUrl = this.helperService.getRedirectUrl(cardValue)
        this.router.navigate([redirectUrl], {queryParams})
          .then(() => {
          this.store.dispatch(new HideLoading(cardValue))
        })
      }))
  }

  private createPostPurchaseUpsell(cardValue) {
    // const userUpsellEligible = this.userService.userInfo?.upsell_eligible
    // if (!userUpsellEligible) {
    //   this.openUpsellForm()
    //   return
    // }
    this.store.dispatch(new ShowLoading(cardValue))
    const requests = [
      this.helperService.getSkipCampaignObservable(this.isOnboarding),
      this.upsellService.createUpsellOffer({name: 'Untitled'})
    ]
    this.subscription$.add(forkJoin(requests)
      .pipe(finalize(() => this.store.dispatch(new HideLoading(cardValue))))
      .subscribe(([skipRes, upsellRes]) => {
        this.router.navigate([`upsell/${upsellRes.id}/offer`]).then()
        this.segmentAnalyticsService.track('Create Upsell Offer', {
          upsell: {
            id: upsellRes.id,
            name: upsellRes.name,
          }
        })
      }))
  }

  private _openGiftCardOnboarding(viewMode?: boolean) {
    const routeEndpoint = viewMode ? `/${GiftCardOnboardingStepName.setup}` : ''
    this.store.dispatch(new ShowLoading('gift-card-onboarding'))
    this.router.navigate([`/onboarding/${RouteHeaderUrl.gift_cards}${routeEndpoint}`], {
      state: {
        showGiftCardOnboarding: true,
        hideNextButtonOnLastStep: true,
        returnURL: this.router.url
      } as GiftCardOnboardinRouterState
    }).then(() => {
      this.store.dispatch(new HideLoading('gift-card-onboarding'))
    })
  }

  private _openSMSOnboarding(step: SMSOnboardingStepName = null) {
    if (this.shopifyService.isEmbedded) {
      this.router.navigate(['/onboarding/sms'], {
        state: {
          showSmsOnboarding: true,
          showCardSteps: true,
          hideNextButtonOnLastStep: true,
          pluginId: null,
          openStep: step,
          returnURL: `/${RouteHeaderUrl.apps}`
        }
      })
      return
    }
    this.store.dispatch(new ShowLoading('sms-onboarding'))
    this.router.navigate(['/onboarding/sms'], {
      state: {
        showSmsOnboarding: true,
        showCardSteps: true,
        hideNextButtonOnLastStep: true,
        pluginId: null,
        returnURL: this.router.url,
        openStep: step
      }
    }).then(() => {
      this.store.dispatch(new HideLoading('sms-onboarding'))
    })
  }

  // private _openEmailOnboarding() {
  //   this.store.dispatch(new ShowLoading('email-onboarding'))
  //   this.router.navigate(['/onboarding/email'], {
  //     state: {
  //       returnURL: this.router.url,
  //     }
  //   }).then(() => {
  //     this.store.dispatch(new HideLoading('email-onboarding'))
  //   })
  // }

  private _showLimitModal(card: CampaignCard) {
    const dialogRef = this.dialog.open(CampaignLimitReachedModalComponent, {
      data: { quiet: true }
    })

    this.subscription$.add(dialogRef.afterClosed().subscribe((res) => {
      if (res === 'replace') {
        this._getStarted(card, true)
      }
    }))
  }

  // private _updateEmailEligibility(emailOnboarded: boolean) {
  //   const emailCard = this.campaignCards.find(card => card.value === CampaignCardValues.EmailAutomation)
  //   const emailOnboardingCard = emailCard.children.find((child) => child.value === CampaignCardValues.EmailOnboarding)

  //   let locked = false
  //   let lockedText = ''
  //   let lockedValue = ''
  //   if (!emailOnboarded) {
  //     locked = true
  //     lockedValue = CampaignCardValues.EmailOnboarding
  //     lockedText = 'Onboarding Required'
  //   } else {
  //     emailOnboardingCard.button_text = 'View'
  //   }
  //   for (const card of this.campaignCards) {
  //     for (const child of card.children) {
  //       this._lockCardIfNeeded(child, locked, lockedValue, lockedText, true)
  //       if (child.toolboxChildren) {
  //         for (const toolboxChild of child.toolboxChildren) {
  //           this._lockCardIfNeeded(toolboxChild, locked, lockedValue, lockedText, true)
  //         }
  //       }
  //     }
  //   }
  // }

  private _updateSMSEligibility(smsEligible: boolean, smsOnboarded: boolean) {
    const smsCard = this.campaignCards.find(card => card.value === CampaignCardValues.SMSAutomation)
    // non-shopify users don't have smsCard
    if (smsCard) {
      const onboardingToolbox = smsCard.children.find((child) => child.toolboxSectionName === 'smsOnboardingStart')
      const tcpaCard = onboardingToolbox.toolboxChildren.find(card => card.value === CampaignCardValues.TCPACompliance)
      let locked = false
      let lockedText = ''
      let lockedValue
      if (!smsEligible) {
        locked = true
        lockedValue = CampaignCardValues.OpenCrisp
        lockedText = 'Contact us to unlock'
        tcpaCard.locked = true
        tcpaCard.locked_value = lockedValue
        tcpaCard.locked_text = lockedText
      } else if (!smsOnboarded) {
        locked = true
        lockedValue = CampaignCardValues.TCPACompliance
        lockedText = 'Complete Onboarding'
        tcpaCard.locked = false
      } else {
        tcpaCard.locked = false
        tcpaCard.button_text = 'View'
      }
      for (const card of this.campaignCards) {
        for (const child of card.children) {
          this._lockCardIfNeeded(child, locked, lockedValue, lockedText)
          if (child.toolboxChildren) {
            for (const toolboxChild of child.toolboxChildren) {
              this._lockCardIfNeeded(toolboxChild, locked, lockedValue, lockedText)
            }
          }
        }
      }
    }
  }

  private _lockCardIfNeeded(card, locked, lockedValue, lockedText) {
    if (card.tcpa_required) {
      card.locked = locked
      card.locked_value = lockedValue
      card.locked_text = lockedText
    }
  }


  private _updateGiftCardEligibility() {
    const giftCard = this.campaignCards.find(card => card.value === CampaignCardValues.GiftCards)
    if (giftCard) {
      const uniqueGiftCard = giftCard.children.find(child => child.value === CampaignCardValues.GiftCardsUnique)
      const giftCardOnBoarding = giftCard.children.find(child => child.value === CampaignCardValues.GiftCardsOnBoarding)
      this._checkGiftCardOnBoardingCompleted(giftCardOnBoarding, uniqueGiftCard)
    }
  }

  private _checkGiftCardOnBoardingCompleted(giftCardOnBoarding, uniqueGiftCard) {
    this.subscription$.add(
      this.giftCardOnboardingApiService.getComplianceOfficer().subscribe(res => {
        const giftCardOnboarded = _.get(res, 'completed', false)
        if (!giftCardOnboarded) {
          uniqueGiftCard.locked = true
          uniqueGiftCard.locked_text = 'Complete Onboarding'
          uniqueGiftCard.locked_value = CampaignCardValues.GiftCardsOnBoarding
        } else {
          giftCardOnBoarding.locked = false
          giftCardOnBoarding.button_text = 'View'
          this.giftCardViewMode = true
        }
      })
    )
  }

  private _updatePremiumEligibility(premium: boolean) {
    const socialProofCard = this.campaignCards.find(card => card.value === CampaignCardValues.SocialProof)
    const AggregateSalesPopChild = socialProofCard.children.find(child => child.value === CampaignCardValues.AggregateSalesPop)
    const AggregateVisitorCountChild = socialProofCard.children.find(child => child.value === CampaignCardValues.AggregateVisitorCount)
    if (AggregateSalesPopChild) {
      AggregateSalesPopChild.locked = !premium
      AggregateSalesPopChild.locked_value = CampaignCardValues.NeedsUpgrade
    }
    if (AggregateVisitorCountChild) {
      AggregateVisitorCountChild.locked = !premium
      AggregateVisitorCountChild.locked_value = CampaignCardValues.NeedsUpgrade
    }
  }

  private _updateSocialQuickstartEligibility(hasOrders: boolean) {
    const socialProofCard = this.campaignCards.find(card => card.value === CampaignCardValues.SocialProof)
    const recentSalesPopChild = socialProofCard.children.find(child => child.value === CampaignCardValues.RecentSalesPop)
    const aggregateSalesPopChild = socialProofCard.children.find(child => child.value === CampaignCardValues.AggregateSalesPop)
    if (recentSalesPopChild) {
      recentSalesPopChild.quickstart = hasOrders
    }
    if (aggregateSalesPopChild) {
      aggregateSalesPopChild.quickstart = hasOrders
    }
  }

  private _refreshFilters() {
    for (const card of this.campaignCards) {
      if (this.selectedTags.length === 0) {
        card.hidden = false
      } else {
        let hide = true
        for (const tag of this.selectedTags) {
          const tagLabel = tag.label.toLowerCase()
          if (card.title.toLowerCase().includes(tagLabel)
              || card.description.toLowerCase().includes(tagLabel)
              || card.keywords.join(' ').includes(tagLabel)
              || JSON.stringify(card.children).toLocaleLowerCase().includes(tagLabel)) {
            hide = false
            break
          }
        }
        card.hidden = hide
      }
    }
  }

  public openCrisp(card: CampaignCard) {
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', `Hello Team, need help unlocking ${card.title} card.`]])
    }
  }

  // this function decides which features to show in different onboarding views
  showFeature(feature) {
    switch (feature) {
      case 'promotion-cards':
      case 'search-bar':
      case 'support-boxes':
        return true
      case 'footer-logout':
        return this.isOnboarding && this.onboardingView === 'default'
      case 'non-shopify':
        return !this.isShopify
      case 'onboarding-header':
        return false // in case we need in the future
      default:
        return false
    }
  }


  public openUpsellForm() {
    const upsellDialog = this.dialog.open(AccessFormModalComponent, {
      width: '700px',
      data: {
        preset: upsellAccessFormPreset
      },
    })

    this.subscription$.add(
      upsellDialog.afterClosed().subscribe((res) => {
        if (res) {
          this.subscription$.add(forkJoin([
            this.userService.updateUserUpsell(true),
            this.apiService.post(`/v1/me/canny/feature_requests/upsell_one_click_checkout/vote_and_comment`, {value: ''})
          ]).subscribe(() => {
            this.router.navigateByUrl(RouteHeaderUrl.upsell)
          }))
        }
      }))
  }

  public openRequestFeatureModal(type: FeatureTypeModal, isWaitingList = false)  {
    const status = this.gettingStartedService.getFeatureVoteStatus()
    if (status[type]?.completed && status[type]?.url) {
      this.openVotedModal(status[type].url, status[type].voteCount || 0, isWaitingList, type)
      return
    }

    const requestDialog = this.dialog.open(VoteFeatureModalComponent, {
      width: '440px',
      data: { featureType: type, isWaitingList },
    })
    this.subscription$.add(
      requestDialog.afterClosed().subscribe((data) => {
        if (data && data.request) {
          const req = data.request
          const { type, description } = req
          const payload = {
            value: description
          }
          this.store.dispatch(new ShowLoading('voteAndCommentToCanny'))
          this.subscription$.add(this.apiService.post(`/v1/me/canny/feature_requests/${type}/vote_and_comment`, payload)
            .pipe(finalize(() => this.store.dispatch(new HideLoading('voteAndCommentToCanny'))))
            .subscribe(
              res => {
                if (res && res.created === true) {
                  const url = _.get(res, 'data.url')
                  const voteCount = _.get(res, 'data.score')
                  this.openVotedModal(url, voteCount, isWaitingList, type)
                  this.updateVoteStatus('upsell_one_click_checkout', url, voteCount)
                } else {
                  console.error('Something went wrong to post request to canny.')
                }
              },
              error => {
                console.error('Unable post request to canny', error)
              }
            ))
        }
      })
    )
  }

  openVotedModal(url, voteCount, isWaitingList, featureType) {
    this.dialog.open(ReceiveRequestModalComponent, {
      width: '560px',
      data: {
        type: isWaitingList ? ReceiveModalType.WaitingList : ReceiveModalType.Vote,
        url,
        voteCount,
        featureType
      }
    })
  }

  updateVoteStatus(key, url, voteCount) {
    this.subscription$.add(this.gettingStartedService.updateFeatureVoteStatus({[key]: {
      completed: true,
      voteCount,
      url
    }}).subscribe())
  }

  watchVideoClicked() {
    this.openTutorialModal('Clicked Overview Video Button')
  }

  openTutorialModal(segmentName): void {
    const video = this.tutorialsService.getVideoById('VgE7nM0r5dM')
    this.dialog.open(VideoTutorialModalComponent, {
      width: '800px',
      data: {
        tutorialVideoItem: video
      }
    })
    this.segmentAnalyticsService.track(segmentName)
  }

  navigateToTutorials(card, event) {
    event.stopPropagation()
    const video = card.videoTutorial.currentVideo
    if (video) {
      this.trackTutorialClick(card)
      this.router.navigate([RouteHeaderUrl.tutorials, video.parentID, video.id || video.cloudflare.id])
    } else {
      console.error('No video found for this card', card)
    }
  }

  showTutorialModal(video) {
    let header = ''
    // switch (video) {
    //   case VideoTutorial.Upsell:
    //     header = 'One-Click Upsell Tutorial'
    //     break
    //   case VideoTutorial.SpinWheel:
    //     header = 'Spin Wheel Pop Up Tutorial'
    //     break
    //   case VideoTutorial.RecentActivity:
    //     header = 'Recent Activity Pop Up Tutorial'
    //     break
    //   case VideoTutorial.EmailOverview:
    //     header = 'Email Overview'
    // }
    // this.dialog.open(VideoTutorialModalComponent, {
    //   width: '800px',
    //   data: {
    //     video: video,
    //     header: header
    //   }
    // })
  }

  trackTutorialClick(preset: CampaignCard) {
    this.segmentAnalyticsService.track(`Clicked Watch Tutorials btn - Start Here - ${preset?.videoTutorial?.segmentEvent}`)
    // if (preset?.videoTutorial?.tutorialSegment === VideoTutorialSegment.Email) {
    //   this.segmentAnalyticsService.track(`Clicked Email Overview Tutorial button`)
    // }
  }
}
