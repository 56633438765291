<div class="text-center QuickSetup-End">
  <div style="position: absolute; top: -25px; left: 0; width: 100%; height: 200px;">
    <div oneConfetti [totalCount]="100" style="width: 100%; height: 100%;"></div>
  </div>
  <h3 class="mb-3">Great job! Your pop up is now live on your website.</h3>
  <!-- <button class="pf-button outline blue md mr-2" (click)="navigateToNewVisitor()">View on Site</button>
  <button class="pf-button filled transparent md" (click)="openSettings()">Customize Further</button> -->
  <div class="my-4">
    <img src="/app_assets/campaigns-list/success.png" alt="">
  </div>
  
  <h5 class="mt-4 mb-3">Want to set up another tool to boost sales or increase conversions?</h5>
  <div>
    <button class="pf-button filled blue" (click)="goToApps()">Explore More</button>
    <button class="pf-button transparent filled ml-3" (click)="closePopup(true)">Not Now</button>
  </div>
</div>