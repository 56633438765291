import { CommonModule } from '@angular/common'
import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core'
import { OneIconComponent } from '../one-icon/one-icon.component'
import { SideNavigationTitle, RouteHeaderUrl } from '../one-header/header-navigation.model'
import { NavigationEnd, Router, RouterModule } from '@angular/router'
import { UserService } from '../../../core/services/user.service'
import { FeatureTypeModal } from '../modals/vote-feature.modal/vote-feature.modal.component'
import { OneMainNavService } from '../one-header/components/one-main-nav/services/one-main-nav.service'
import { Subscription, combineLatest, filter, map } from 'rxjs'
import { SMSService } from '../../services/sms.service'
import { ShopifyService } from '../../../core/services/shopify.service'
import { SegmentAnalyticsService } from '../../services/segment-analytics.service'
import { UserRole } from '../../models/user/user.model'
import { ToggleNavDropdownDirective } from './services/toggle-nav-dropdown.directive'
import { SideNavService } from './services/one-side-nav.service'
import { OneMainLogoComponent } from './one-main-logo/one-main-logo.component'
import { FormsModule } from '@angular/forms'
import { OneHeaderService } from '../one-header/services/one-header.service'
import { BreakpointService } from '../../../core/services/breakpoint.service'
import { ShopSwitchComponent } from './one-shop-switch/one-shop-switch.component'

export interface SideNavModel {
  name: string
  icon?: string
  iconSrc?: string
  url?: string
  visible: boolean
  children?: SideNavModel[]
  _expanded?: boolean
  method?: ($event) => void
  routerLinkActiveOptions?: {
    exact: boolean
  }
  subRoutesMatch?: RegExp[]
  forceActive?: boolean
  separator?: boolean
}

@Component({
  selector: 'one-side-nav',
  templateUrl: 'one-side-nav.component.html',
  styleUrls: ['one-side-nav.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    OneIconComponent,
    RouterModule,
    OneMainLogoComponent,
    ToggleNavDropdownDirective,
    ShopSwitchComponent,
  ],
  providers: [
    OneMainNavService,
  ],
})

export class OneSideNavComponent implements OnInit, OnDestroy {
  private subscription = new Subscription()
  routeHeaderUrl = RouteHeaderUrl
  userSMSEligible = false
  isShopifyShop = false
  showCoPilot = false
  shopifyPlan = null
  userRoles = UserRole
  smsOnboardingPassed = false
  isAdmin = false
  readonly onboardingSignupAppsURL = '/onboarding/signup/new/apps/view/standard'

  isPinned$ = this.sideNavService.pinnedSideNav$
  filterInput = ''

  SIDE_NAVIGATION: SideNavModel[] = []
  SIDE_NAVIGATION_REF: SideNavModel[] = []

  bp$ = this.breakpointService.bp$

  constructor(
    private oneMainNavService: OneMainNavService,
    private oneHeaderService: OneHeaderService,
    private smsService: SMSService,
    private shopifyService: ShopifyService,
    private router: Router,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private sideNavService: SideNavService,
    private userService: UserService,
    private breakpointService: BreakpointService,
  ) { }

  @HostBinding('class._toggle-active') toggleActive = false
  @HostBinding('class._force-active') get forceActive() {
    return this.bp$.value._1920.active
  }

  @HostListener('mouseenter') onMouseover() {
    this.sideNavService.toggleActive(true)
  }
  @HostListener('mouseleave') onMouseleave() {
    this.sideNavService.toggleActive(false)
  }

  ngOnInit() {
    this.isShopifyShop = this.userService.isShopifyShop
    this.shopifyPlan = this.userService?.userInfo?.shop?.profile?.plan_name || null
    this.showCoPilot = !['partner_test', 'affiliate'].includes(this.shopifyPlan)
    this.userSMSEligible = this.smsService.userIsEligible(this.userService?.userInfo)
    this.isAdmin = this.userService?.userInfo?.role === this.userRoles[UserRole.admin] || this.userService?.userInfo?.role === this.userRoles[UserRole.support]
    this.setNavigation(true)
    this.checkActiveRoute()
    this.subscription.add(
      this.smsService.onboardingPassed().subscribe(value => this.smsOnboardingPassed = value)
    )
    this.subscription.add(
      combineLatest([
        this.sideNavService.toggleActive$,
        this.sideNavService.pinnedSideNav$,
        this.oneHeaderService.mobileMenuSubject$,
        this.bp$,
      ]).subscribe(([toggleActive, pinnedSideNav, mobileMenuSubject, bp]) => {
        this.toggleActive = toggleActive || pinnedSideNav || (mobileMenuSubject && !bp._660.active)
      })
    )
    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.onFilterChange()
      })
    )
  }

  // TODO: (this is ugly) move it to a standalone file, decide what to do with dependencies in visibility and methods
  setNavigation(initial: boolean = false) {
    this.SIDE_NAVIGATION = [
      {
        name: 'AI Platform',
        visible: this.showCoPilot || this.isAdmin,
        separator: true,
      },
      
      {
        name: SideNavigationTitle.ai_assistans,
        icon: 'sparkles-outline',
        visible: this.showCoPilot || this.isAdmin,
        children: [
          {
            name: SideNavigationTitle.ai_sidekick,
            url: '/' + RouteHeaderUrl.oneGPT,
            visible: true,
          },
          {
            name: SideNavigationTitle.ai_co_pilot,
            icon: 'lock-closed-outline',
            method: ($event) => this.requestFeature('one_store_copilot', true),
            visible: true,
          }
        ]
      },

      {
        name: 'Engagement Platform',
        visible: true,
        separator: true,
      },

      {
        name: SideNavigationTitle.analytics,
        icon: 'trending-up-outline',
        visible: true,
        url: '/' + RouteHeaderUrl.dashboard,
        children: [
        ]
      },

      {
        name: SideNavigationTitle.messaging,
        icon: 'chatbox-ellipses-outline',
        visible: true,
        children: [
          {
            name: SideNavigationTitle.emails,
            url: '/' + RouteHeaderUrl.messaging + '/' + RouteHeaderUrl.emails + '/' + RouteHeaderUrl.campaigns,
            visible: true,
          },
          {
            name: SideNavigationTitle.sms,
            url: '/' + RouteHeaderUrl.messaging + '/' + RouteHeaderUrl.sms + '/' + RouteHeaderUrl.campaigns,
            visible: this.isShopifyShop,
          },
          {
            name: SideNavigationTitle.push_notifications,
            icon: 'lock-closed-outline',
            method: ($event) => this.requestFeature('push_notification'),
            visible: true,
          },
          {
            name: SideNavigationTitle.whatsapp,
            icon: 'lock-closed-outline',
            method: ($event) => this.requestFeature('whats_app'),
            visible: true,
          },
        ]
      },

      {
        name: SideNavigationTitle.popups,
        icon: 'duplicate-outline',
        visible: true,
        children: [
          {
            name: SideNavigationTitle.subscribe_forms,
            url: '/' + RouteHeaderUrl.popups + '/' + RouteHeaderUrl.subscribe_forms,
            visible: true,
          },
          {
            name: SideNavigationTitle.announcements,
            url: '/' + RouteHeaderUrl.popups + '/' + RouteHeaderUrl.announcements,
            visible: true,
          },
          {
            name: SideNavigationTitle.social_proof,
            url: '/' + RouteHeaderUrl.popups + '/' + RouteHeaderUrl.social_proof,
            visible: true,
          },
          // {
          //   name: 'Cookie Consent',
          //   method: ($event) => this.requestFeature('cookie_consent' as any),
          //   visible: true,
          //   icon: 'lock-closed-outline',
          // },
          // {
          //   name: 'Age Verification',
          //   method: ($event) => this.requestFeature('age_verification' as any),
          //   visible: true,
          //   icon: 'lock-closed-outline',
          // }
        ]
      },

      {
        name: SideNavigationTitle.upsell,
        icon: 'arrow-up-circle-outline',
        visible: this.isShopifyShop,
        children: [
          {
            name: SideNavigationTitle.upsell_checkout,
            url: '/' + RouteHeaderUrl.upsell,
            visible: true,
          },
          {
            name: SideNavigationTitle.upsell_email,
            url: '/email-automation-builder/upsell/theme',
            visible: true,
          },
          {
            name: SideNavigationTitle.upsell_sms,
            method: ($event) => this.navigateToUpsellSms($event),
            visible: true,
          },
        ]
      },

      {
        name: SideNavigationTitle.rewards,
        icon: 'gift-outline',
        visible: true,
        children: [
          {
            name: SideNavigationTitle.coupons,
            url: '/' + RouteHeaderUrl.rewards + '/' + RouteHeaderUrl.coupons,
            visible: true,
          },
          {
            name: SideNavigationTitle.gift_cards,
            url: '/' + RouteHeaderUrl.rewards + '/' + RouteHeaderUrl.gift_cards,
            visible: this.isShopifyShop,
          },
          {
            name: SideNavigationTitle.store_credits,
            icon: 'lock-closed-outline',
            method: ($event) => this.requestFeature('store_credits'),
            visible: true,
          },
          {
            name: SideNavigationTitle.loyalty_points,
            icon: 'lock-closed-outline',
            method: ($event) => this.requestFeature('loyalty_point'),
            visible: true,
          },
          {
            name: SideNavigationTitle.download_links,
            icon: 'lock-closed-outline',
            method: ($event) => this.requestFeature('download_links'),
            visible: true,
          },
        ]
      },

      {
        name: SideNavigationTitle.reviews,
        icon: 'star-outline',
        visible: true,
        children: [
          {
            name: SideNavigationTitle.text_reviews,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('products_text_reviews'),
          },
          {
            name: SideNavigationTitle.photo_reviews,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('products_photo_reviews'),
          },
          {
            name: SideNavigationTitle.video_reviews,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('products_video_reviews'),
          },
        ]

      },

      {
        name: 'Data Platform',
        visible: true,
        separator: true,
      },

      {
        name: SideNavigationTitle.customer_platform,
        icon: 'image-outline',
        visible: !this.isShopifyShop,
        url: '/' + RouteHeaderUrl.customer_data + '/' + RouteHeaderUrl.contacts,
        children: [
        ]
      },
      {
        name: 'Customers',
        icon: 'person-outline',
        visible: this.isShopifyShop,
        url: '/' + RouteHeaderUrl.dashboard,
        children: [
          {
            name: SideNavigationTitle.contacts,
            url: '/' + RouteHeaderUrl.customer_data + '/' + RouteHeaderUrl.contacts,
            visible: true,
          },
          {
            name: SideNavigationTitle.subscribers,
            url: '/' + RouteHeaderUrl.customer_data + '/' + RouteHeaderUrl.subscribers,
            visible: true,
          },
          {
            name: SideNavigationTitle.profiles,
            icon: 'lock-closed-outline',
            method: ($event) => this.requestFeature('customers_profiles'),
            visible: true,
          },
          {
            name: SideNavigationTitle.segments,
            icon: 'lock-closed-outline',
            method: ($event) => this.requestFeature('customers_segments', true),
            visible: true,
          },
        ]
      },
      {
        name: SideNavigationTitle.products,
        icon: 'cart-outline',
        visible: this.isShopifyShop,
        children: [
          {
            name: SideNavigationTitle.product_catalog,
            url: '/' + RouteHeaderUrl.products,
            routerLinkActiveOptions: {exact: true},
            visible: true,
            subRoutesMatch: [/^\/products\/\d+\/edit$/, /^\/products\/new$/],
            forceActive: false,
          },
          {
            name: SideNavigationTitle.product_collections,
            url: '/' + RouteHeaderUrl.products_collections,
            routerLinkActiveOptions: {exact: true},
            visible: true,
          }
        ]
      },
      {
        name: 'Orders',
        icon: 'file-tray-outline',
        visible: this.isShopifyShop,
        children: [
          {
            name: SideNavigationTitle.placed_orders,
            url: '/' + RouteHeaderUrl.placed_orders,
            visible: true,
          },
          {
            name: 'Abandoned Checkouts',
            icon: 'lock-closed-outline',
            visible: true,
          },
          {
            name: 'Abandoned Carts',
            icon: 'lock-closed-outline',
            visible: true,
          },
        ]
      },
     
      {
        name: 'Omnichannel Platform',
        visible: true,
        separator: true,
      },

      {
        name: 'Stores',
        icon: 'storefront-outline',
        visible: true,
        children: [
          {
            name: SideNavigationTitle.shopify_store,
            visible: true,
            method: ($event) => this.openShopifyStore(),
          },
          {
            name: SideNavigationTitle.tiktok_store,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('sales_channel_tiktok_store', true),
          },
        ]
      },
      {
        name: 'Socials',
        icon: 'share-social-outline',
        visible: true,
        children: [
          {
            name: SideNavigationTitle.youtube_store,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('sales_channel_youtube_store', true),
          },
          {
            name: SideNavigationTitle.instagram_store,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('sales_channel_instagram_store', true),
          },
        ]
      },
      {
        name: 'Marketplaces',
        icon: 'basket-outline',
        visible: true,
        children: [
          {
            name: SideNavigationTitle.ebay_store,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('sales_channel_ebay_store', true),
          },
          {
            name: SideNavigationTitle.amazon_store,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('sales_channel_amazon_store', true),
          },
        ]
      },
      {
        name: 'Search',
        icon: 'search-circle-outline',
        visible: true,
        children: [
          {
            name: SideNavigationTitle.google_store,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('sales_channel_google_store', true),
          },
          {
            name: SideNavigationTitle.bing_store,
            icon: 'lock-closed-outline',
            visible: true,
            method: ($event) => this.requestFeature('sales_channel_bing_store', true),
          },
        ]
      },

      {
        name: 'Setup & Learn',
        visible: true,
        separator: true,
      },


      {
        name: SideNavigationTitle.apps,
        iconSrc: '/app_assets/icons/apps-icon.svg',
        visible: true,
        children: [
          {
            name: SideNavigationTitle.app_library,
            url: '/' + RouteHeaderUrl.apps,
            visible: true,
          },
          {
            name: SideNavigationTitle.app_integrations,
            url: '/' + RouteHeaderUrl.messaging + '/' + RouteHeaderUrl.integrations,
            visible: true,
          },
        ]
      },

      {
        name: SideNavigationTitle.templates,
        icon: 'color-palette-outline',
        visible: this.isShopifyShop,
        children: [
          {
            name: SideNavigationTitle.holidays,
            url: '/' + RouteHeaderUrl.holidays,
            visible: true,
          },
        ]
      },

      {
        name: SideNavigationTitle.tutorials,
        icon: 'flash-outline',
        visible: this.isShopifyShop,
        children: [
          {
            name: SideNavigationTitle.videos,
            // method: ($event) => this.openVideoTutorials(),
            url: '/' + RouteHeaderUrl.tutorials,
            visible: true,
          },
          {
            name: SideNavigationTitle.articles,
            method: ($event) => this.openKnowledgeBase(),
            visible: true,
          },
        ]
      },

    ]
    // clone the navigation to reference it so we can avoid showing hidden items in search results
    this.SIDE_NAVIGATION_REF = JSON.parse(JSON.stringify(this.SIDE_NAVIGATION))
  }

  onFilterChange() {
    const currentRoute = this.router.url

    this.SIDE_NAVIGATION.forEach((navItem) => {
      if (navItem.separator) {
        navItem.visible = false
        return
      }
      navItem.visible = navItem.name.toLowerCase().includes(this.filterInput.toLowerCase())
      navItem._expanded = navItem.visible
      if (navItem.children) {
        const haveMatchedChildren = navItem.children.some((child) => {
          child.visible = child.name.toLowerCase().includes(this.filterInput.toLowerCase())
          return child.visible
        })
        // if any child is visible, then parent should be visible too
        if (haveMatchedChildren) {
          navItem.visible = haveMatchedChildren
          navItem._expanded = navItem.visible
        // if children didn't match, then check if parent matches, if so - expand it and make all children visible
        } else if (navItem.visible) {
          navItem._expanded = navItem.visible
          navItem.children?.forEach((child) => child.visible = true)
        }
      }
    })
    // Compare SIDE_NAVIGATION visibility with SIDE_NAVIGATION_REF visibility
    // so we don't show initially hidden items in search results
    this.SIDE_NAVIGATION.forEach((navItem, index) => {
      if (navItem.visible && !this.SIDE_NAVIGATION_REF[index].visible) {
        navItem.visible = false
      }
      // children
      if (navItem.children) {
        navItem.children.forEach((child, childIndex) => {
          this.forceActiveIfNeeded(child, currentRoute)
          if (child.visible && !this.SIDE_NAVIGATION_REF[index].children[childIndex].visible) {
            child.visible = false
          }
        })
      }
    })
    if (this.filterInput === '') {
      this.setNavigation()
      this.checkActiveRoute()
    }
  }

  navigateToUpsellSms(event) {
    event.preventDefault()
    if (!this.smsOnboardingPassed && this.shopifyService.isEmbedded) {
      this.router.navigate(['/onboarding/sms'], {
        state: {
          showSmsOnboarding: true,
          showCardSteps: true,
        },
      })
      return
    }
    this.router.navigate([`/${this.routeHeaderUrl.messaging}/${this.routeHeaderUrl.sms}/automations/new`])
  }

  requestFeature(type: FeatureTypeModal, isWaitingList = false) {
    this.oneMainNavService.requestFeature(type, isWaitingList)
  }

  trackHolidayPageClick() {
    this.segmentAnalyticsService.track('Clicked Holidays Page')
  }

  openVideoTutorials() {
    this.segmentAnalyticsService.track('Clicked Video Tutorials Nav')
    this.router.navigate([`/${this.routeHeaderUrl.tutorials}`])
  }

  openKnowledgeBase() {
    this.segmentAnalyticsService.track('Clicked Knowledge Base Nav')
    window.open(`https://help.one.store/`, '_blank')
  }

  openShopifyStore() {
    this.segmentAnalyticsService.track('Clicked Shopify Store Nav')
    window.open('https://shopify.one.store/app/dashboard', '_blank')
  }

  togglePin() {
    this.sideNavService.togglePinned()
  }

  checkActiveRoute() {
    const currentRoute = this.router.url

    this.SIDE_NAVIGATION.forEach(item => {
      if (item.children) {
        item.children.forEach(child => {
          this.forceActiveIfNeeded(child, currentRoute)
          if (child.url === currentRoute || child.forceActive) {
            item._expanded = true
          }
        })
      }
    })
  }

  toggleMobileMenu() {
    this.oneHeaderService.mobileMenuSubject$.next(!this.oneHeaderService.mobileMenuSubject$.value)
  }

  forceActiveIfNeeded(item: SideNavModel, currentRoute: string) {
    if (item.url && item.subRoutesMatch) {
      item.forceActive = item.subRoutesMatch.some(r => r.test(currentRoute))
    }
  }

  clearFilter() {
    this.filterInput = ''
    this.onFilterChange()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
