import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import {CountriesService} from '../../../core/services/countries.service'
import * as _ from 'lodash'
import {filter, map, shareReplay, switchMap, tap} from 'rxjs/operators'
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs'
import {finalize} from 'rxjs/operators'
import {AddressService} from './address.service'
import {UserService} from '../../../core/services/user.service'
import { ApiUserService } from '../../../core/services/api/api-user.service'

interface Location {
  id: string;
  name: string;
  code: string;
}

@Component({
  selector: 'pf-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent implements OnInit, OnDestroy {
  // need for case of `get started`
  @Input() isSaveButtonEnabled = true
  // for profile page
  @Input() readonly = false
  readonlyAddress = {
    country: '',
    state: '',
    city: '',
  }

 @Input() form: UntypedFormGroup = this.fb.group({
    country: [{
      id: '',
      code: '',
      name: '',
    }],
    state: [{
      id: '',
      code: '',
      name: '',
    }],
    city: '',
  })

  @Input() formTouched: boolean = false

  locationSaved = false

  // loadings
  countriesLoading = false
  statesLoading = false

  error: any

  submit$ = this.addressService.submit$

  // lists
  countriesList$: Observable<Location[]> = this.countriesService.getCountries().pipe(
    map(res => [..._.sortBy(res || [], 'name')]),
    shareReplay(1),
    tap(() => {
      this.countriesLoading = true
    }),
    finalize(() => this.countriesLoading = false),
  )

  stateListUpdate$ = new BehaviorSubject(null)
  statesList$: Observable<Location[]> = this.stateListUpdate$.pipe(
    filter(countryCode => !!countryCode),
    tap(() => {
      this.statesLoading = true
    }),
    switchMap(countryCode => {
      this.form.patchValue({state: null, city: null})
      if (countryCode !== 'empty') {
        return this.countriesService.getStates(countryCode).pipe(
          map(res => {
            const states = [..._.sortBy(res || [], 'name')]
            if (states.length === 0) {
              this.form.controls.state.disable()
            } else {
              this.form.controls.state.enable()
            }
            return states
          }),
          finalize(() => {
            this.statesLoading = false
          }),
        )
      }
      this.statesLoading = false
      return of([])
    }),
  )

  subscription = new Subscription()

  constructor(
    private fb: UntypedFormBuilder,
    private countriesService: CountriesService,
    private apiUserService: ApiUserService,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    private addressService: AddressService) {
    this.stateListUpdate$.next('empty')
  }

  ngOnInit() {
    this.getData()
    this.subscribeToSubmit()
    if (this.readonly) {
      const userInfo = this.userService.userInfo
      this.readonlyAddress = {
        country: _.get(userInfo, 'address.country.name'),
        state: _.get(userInfo, 'address.state.name'),
        city: _.get(userInfo, 'address.city'),
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  subscribeToSubmit() {
    this.subscription.add(this.submit$.pipe().subscribe(() => this.saveLocation()))
  }

  getData() {
    this.subscription.add(this.apiUserService.getUserAddress().subscribe(res => {
      this.form.get('country').patchValue(res.country ? res.country : null)
      this.stateListUpdate$.next(res.country ? res.country.code : 'empty')
      this.form.get('state').patchValue(res.state ? res.state : null)
      this.form.get('city').patchValue(res.city ? res.city : null)
    }))
  }

  onCountrySelect() {
    this.stateListUpdate$.next(this.form.value.country.code)
  }

  onStateSelect() {
  }

  saveLocation() {
    const payload = {
      country_id: this.form.value.country && this.form.value.country.id,
      state_id: this.form.value.state && this.form.value.state.id,
      city: this.form.value.city,
    }
    this.subscription.add(this.apiUserService.updateUserAddress(payload).subscribe(
      next => {
        this.locationSaved = true
        this.ref.markForCheck()
        setTimeout(() => {
          this.locationSaved = false
          this.ref.markForCheck()
        }, 5000)
      },
      error => {
        this.error = error
        setTimeout(() => {
          this.error = null
          this.ref.markForCheck()
        }, 5000)
        this.ref.markForCheck()
      },
    ))
  }

  compareLocation(locationOne: Location, locationTwo: Location) {
    return locationOne && locationTwo ? locationOne.id === locationTwo.id : locationOne === locationTwo
  }

  get formInvalid() {
    return !this.form.get('country')
      && !this.form.get('state')
      && !this.form.get('city')
      || !this.form.dirty
      || this.form.invalid
  }
}
