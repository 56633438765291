import { JFQuestionType } from "../../shared/modules/jotform/models/jotform.model"

export const JFNewFormPreset = {
  id: '213602746451048',
  class: '_new',
  logo: '/app_assets/one-logo.svg',
  animation: 'right',
  welcomePage: {
      active: true,
      buttonText: 'START',
      showQuestionCount: true,
      title: 'Welcome to ONE',
      subTitle: 'Please take a minute to answer 3 short questions.',
  },
  prefilled: [
    null,
    {
      qid: '11',
      answers: {
          field_1: null,
          field_2: null,
          field_3: null,
          field_4: null,
          field_5: null,
          field_6: null,
          field_7: null,
          field_8: null
      }
    }
  ],
  questions: [
      {
          qid: '15',
          type: JFQuestionType.ControlTextarea,
          text: 'How can ONE app help you?',
          description: 'Tell us why you installed us and please add as much detail as you can.  \nYour answer will be forwarded to our customer support team.\nYour answer will be used to better assist you reaching your goals with our app.',
          required: true,
          hint: 'Type here...',
          entryLimit: 0,
          entryLimitMin: 15,
      },
      {
          qid: '39',
          type: JFQuestionType.ControlNumber,
          text: 'So far, how many Apps do you have installed?',
          description: '',
          required: true,
          hint: 'ex: 23',
          maxValue: 250,
      },
      {
          qid: '41',
          type: JFQuestionType.ControlTextarea,
          text: 'If any, which Apps did not meet your expectations? And why?',
          description: '',
          required: false,
          hint: 'Type here...',
          entryLimit: 0,
          entryLimitMin: 0,
      }
  ],
  answers: {}
}

export const JFNewFormPresetV2 = {
  id: '223340400544139',
  class: '_new',
  logo: '/app_assets/one-logo.svg',
  animation: 'right',
  welcomePage: {
      active: true,
      buttonText: 'START',
      showQuestionCount: true,
      title: 'Welcome to ONE',
      subTitle: 'Please take a minute to answer 2 short questions.',
  },
  prefilled: [
    null, // this is here so indexing starts at 1 :D
    {
      name: 'prefilled',
      qid: '11',
      answers: {
          field_1: null,
          field_2: null,
          field_4: null,
          field_5: null,
          field_6: null,
          field_7: null,
          field_8: null,
          field_9: null, // accounte email
      },
    },
    {
      name: 'prefilled2',
      qid: '53',
      answers: {
          field_1: null,
          field_2: null,
          field_3: null,
          field_4: null,
          field_5: null,
          field_6: null,
          field_7: null,
          field_8: null
      }
    },
    {
      name: 'prefilled3',
      qid: '62',
      answers: {
        field_9: null, // contacts
        field_10: null, // customers
        field_11: null, // subscribers
        field_12: null, // active profiles
      }
    },
    { // phone verification prefilled data
      name: 'prefilled4',
      qid: '63',
      answers: {
        field_1: null, // shop user name
        field_2: null, // shop user email
        field_5: null, // shop user phone
        field_4: null, // shop user phone verified
        field_6: null, // shop user phone verified reason
        field_7: null, // fullstory session url
        field_8: null, // crisp session url
      }
    },
    { // plan status prefilled data
      name: 'prefilled5',
      qid: '64',
      answers: {
        field_1: null, // plan selected name
        field_2: null, // plan selected price
        field_3: null, // plan charge approved
        field_4: null, // plan approval time (seconds)
      }
    },
    { // onboarding status data
      name: 'prefilled6',
      qid: '103',
      answers: {
        field_1: null, // onboarding status
        field_2: null, // onboarding stage
        field_3: null, // onboarding call
        field_4: null, // onboarding locked
        field_5: null, // onboarding locked reason
        field_6: null, // onboarding unlocked flow
      }
    },
  ],
  questions: [
        {
          "qid": "42",
          "type": JFQuestionType.ControlImageChoice,
          "text": "What do you want to setup first?",
          "description": "Get ready to simplify your workload & ditch dozens of apps for ONE.",
          "required": true,
          "active": false,
          "error": false,
          "options": [
              {
                  "text": "Social Proof",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "SMS Marketing",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Email Marketing",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "One-Click Upsell",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Cart Recovery",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Informational Pop Up",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Subscribe Pop Up",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Spin-to-Win",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Discounts",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Gift Cards",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Customers (CDP)",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Products (PDP)",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "All Apps",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              },
              {
                  "text": "Not Sure / Other",
                  "link": "https://www.jotform.com/images/image_placeholder.png",
                  "checked": false
              }
          ],
          "multiselect": false
      },
      {
        "qid": "44",
        "type": JFQuestionType.ControlCheckbox,
        "text": "How can we best help you publish your first app campaign?",
        "description": "Our experts are available to help you in English, Español or 中文.",
        "required": true,
        "active": false,
        "error": false,
        "otherText": null,
        "spreadCols": 2,
        "options": [
            {
                "value": "Email Support",
                "checked": false
            },
            {
                "value": "SMS Text Support",
                "checked": false
            },
            {
                "value": "Phone Support",
                "checked": false
            },
            {
                "value": "Live Chat Support",
                "checked": false
            },
            {
                "value": "Demo Call",
                "checked": false
            },
            {
                "value": "Tutorial Videos",
                "checked": false
            }
        ]
    }
  ],
  answers: {}
}

export const JFReturningFormPreset = {
    id: '220111124702434',
    logo: '/app_assets/one-logo.svg',
    class: '_returning',
    animation: 'right',
    welcomePage: {
        active: true,
        buttonText: 'LET\'S GO',
        showQuestionCount: true,
        title: 'Welcome Back',
        subTitle: '',
    },
    prefilled: [
      null,
      {
        name: 'prefilled',
        qid: '11',
        answers: {
            field_1: null,
            field_2: null,
            field_3: null,
            field_4: null,
            field_5: null,
            field_6: null,
            field_7: null,
            field_8: null
        }
      }
    ],
    questions: [
        {
            qid: '42',
            type: JFQuestionType.ControlCheckbox,
            text: 'Which statement(s) below are true?',
            description: 'Our app was either re-activated or re-installed on your store again.',
            required: false,
            entryLimitMin: 0,
            otherText: 'Type other option here...',
            spreadCols: 1,
            options: [
                {
                  value: "My store was temporarily paused or closed",
                  checked: false
                },
                {
                  value: "My store deleted ONE app on purpose",
                  checked: false
                },
                {
                  value: "My store deleted ONE app by mistake",
                  checked: false
                },
                {
                  value: "I am personally installing ONE app for the first time",
                  checked: false
                },
                {
                  value: "Not sure",
                  checked: false
                },
                {
                  type: "other",
                  placeholder: "Type other option here...",
                  value: "",
                  checked: false
                }
              ]
        },
        {
            qid: '15',
            type: JFQuestionType.ControlTextarea,
            text: 'How can ONE app help you?',
            description: 'Tell us why you installed us and please add as much detail as you can.\nYour answer will be forwarded to our customer support team.\nYour answer will be used to better assist you reaching your goals with our app.',
            required: true,
            hint: 'Type here...',
            entryLimit: 0,
            entryLimitMin: 15,
            otherText: null
        }
    ],
    answers: {}
}
