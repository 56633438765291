import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import _ from 'lodash'
import { of, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { RewardsResponse } from '../../../../../../core/services/api/api-rewards.service'
import { CampaignDataService } from '../../../../../../core/services/data-services/campaign-data.service'
import { RewardsService } from '../../../../../../core/services/rewards-service.service'
import { CampaignPluginName } from '../../../../../../shared/models/campaign/campaign'
import { CouponBoxNotificationParams } from '../../../../../../shared/models/campaign/coupon-box-notification.model'
import { CouponCode, CouponCodeState } from '../../../../../coupon-codes/models/coupon-code'
import { RewardsType } from '../../../../../coupon-codes/models/rewards-model'
import { FortuneWheelSlice, FortuneWheelSliceType } from '../../../../plugins/fortune-wheel/models/fortune-wheel.model'
import { PluginSetupResult } from '../../../models/quick-setup.model'
import { QuickSetupService } from '../../../services/quick-setup.service'
import { UserShopType } from '../../../../../../shared/models/user/user-shop-type.model'
import { UserService } from '../../../../../../core/services/user.service'
import { CurrencyPipe } from '@angular/common'
import { MarketingEmailsApiService } from '../../../../../../shared/modules/marketing-emails/services/marketing-emails-api.service'

@Component({
  templateUrl: './quick-setup-inputs.component.html',
  styleUrls: ['./quick-setup-inputs.component.scss']
})
export class QuickSetupInputsComponent implements OnInit, OnDestroy {
  private subscription = new Subscription()
  public rewardsType = RewardsType
  public pluginType = this.quickSetupService.currentPlugin
  public campaignPluginName = CampaignPluginName
  public showCustomSetupButton = false
  public slices: FortuneWheelSlice[] = []

  form: UntypedFormGroup = this.fb.group({
    email_automation: [true]
  })
  couponCodesResponse: RewardsResponse = {
    rewards: [],
    total_count: 0,
    total_pages: 1
  }
  isShopify = _.get(this.userService.userInfo, 'shop.type') === UserShopType.ShopifyShop
  userCurrency = this.userService.userInfo?.shop?.profile?.currency || 'USD'
  userCurrencySymbol = this.currencyFormatPipe.transform(1, this.userCurrency).replace(/\d*\.?\d*/g, '')

  constructor(
    private fb: UntypedFormBuilder,
    private campaignDataService: CampaignDataService,
    private currencyFormatPipe: CurrencyPipe,
    private quickSetupService: QuickSetupService,
    private userService: UserService,
    private marketingEmailsApiService: MarketingEmailsApiService,
    private rewardsService: RewardsService,
  ) { }

  ngOnInit(): void {
    switch (this.quickSetupService.currentPlugin) {
      case CampaignPluginName.CouponBoxNotification:
        this.form.addControl('coupon_id', this.fb.control(null))
        this.getCoupons()
        break
      case CampaignPluginName.FortuneWheel:
        // Take slices from current campaign and sort them by index
        this.slices = _.cloneDeep(this.quickSetupService.currentCampaign[CampaignPluginName.FortuneWheel].slices)
                      .sort((slice_one, slice_two) => slice_one.index - slice_two.index)
        const formArray = []
        for (let i = 0; i < 3; i++) {
          formArray.push(this.createSlice(this.slices[i]))
        }
        this.form.addControl('slices', this.fb.array(formArray))
        this.getCoupons()
        break
      case CampaignPluginName.NewsLetterNotification:
        break
    }

    this.subscription.add(
      this.rewardsService.couponCodesResponse$.subscribe(response => {
        this.couponCodesResponse = response
      })
    )

    this.subscription.add(
      this.quickSetupService.akcsTotal$.subscribe(akcs => {
        this.showCustomSetupButton = akcs?.active_known_customers_total > 250
      })
    )
  }

  createSlice(slice: FortuneWheelSlice) {
    return this.fb.group({
      id: slice.id,
      index: slice.index,
      type: slice.type,
      label: [slice.label, [Validators.required, Validators.maxLength(280)]],
      reward: this.fb.group({
        id: slice.reward.id,
        reward_type: slice.reward.reward_type,
      }),
      gravity: [slice.gravity, [Validators.min(0)]],
      percentage: 0,
      code_status: CouponCodeState.active,
    })
  }

  getCoupons() {
    this.subscription.add(
      this.quickSetupService.fetchCoupons().subscribe(response => {
        this.couponCodesResponse = response
        const rewards = this.couponCodesResponse.rewards
        // Find 10, 15 and Free Shipping coupons, if not found, take first coupon with value 10, 15 or 100
        const coupons: CouponCode[] = [
          (rewards.find(coupon => coupon.prefix_code === '10OFF') || rewards.find(coupon => coupon['data']?.value === 10)) as CouponCode,
          (rewards.find(coupon => coupon.prefix_code === '15OFF') || rewards.find(coupon => coupon['data']?.value === 15)) as CouponCode,
          (rewards.find(coupon => coupon.prefix_code === 'SHIP') || rewards.find(coupon => coupon['data']?.value === 100)) as CouponCode,
        ]
        if (this.pluginType === CampaignPluginName.CouponBoxNotification) {
          // if the campaign already has a coupon, use it, otherwise use the first coupon
          if ((this.quickSetupService.params$.value as CouponBoxNotificationParams)?.reward?.id) {
            this.form.controls.coupon_id.patchValue((this.quickSetupService.params$.value as CouponBoxNotificationParams).reward.id)
          } else {
            this.form.controls.coupon_id.patchValue(coupons[0].id)
          }
        } else if (this.pluginType === CampaignPluginName.FortuneWheel) {
          // Assign coupons to slices
          (this.form.get('slices') as UntypedFormArray).controls.forEach((control, index) => {
            control.get('reward.id').patchValue(coupons[index].id)
            control.get('type').patchValue(FortuneWheelSliceType.COUPON)
            control.get('reward.reward_type').patchValue(FortuneWheelSliceType.COUPON)
            control.get('label').patchValue(coupons[index].title)
            control.get('gravity').patchValue(10)
            control.get('code_status').patchValue(CouponCodeState.active)
          })
          this.updateFWSlices()
        }
      })
    )
  }

  onFWRewardUpdate(index = 0, e: {value: string}, skipUpdate = false) {
    const control = (this.form.get('slices') as FormArray).controls[index]
    if (e.value) {
      const coupon = this.couponCodesResponse.rewards.filter(c => c.id === e.value)[0] as CouponCode
      if (coupon && control) {
        control.get('label').patchValue(coupon.title)
        control.get('gravity').patchValue(10) // FIXME: ?
        control.get('reward.id').patchValue(coupon.id)
        control.get('reward.reward_type').patchValue(coupon.reward_type)
      }
    } else {
      if (control) {
        control.get('label').patchValue('')
        control.get('type').patchValue(FortuneWheelSliceType.NO_PRIZE)
        control.get('gravity').patchValue(0)
        control.get('reward.id').patchValue(null)
        control.get('reward.reward_type').patchValue(null)
      }
    }
    if (!skipUpdate) {
      this.updateFWSlices()
    }
  }

  onBCBRewardUpdate(e: {value: string}) {
    const params = this.quickSetupService.params$.value as CouponBoxNotificationParams
    if (e.value) {
      const selectReward = this.couponCodesResponse.rewards.filter(c => c.id === e.value)[0]
      params.reward = {
        id: e.value,
        reward_type: RewardsType.coupon_code,
        title: selectReward.title,
      } as any
      this.quickSetupService.setBCBDefaultPromptCopy(params, selectReward)
    } else {
      delete params.reward
    }
    this.quickSetupService.params$.next(params)
  }

  updateFWSlices() {
    const formValues = this.form.value.slices
    const slice0Active = formValues[0].type !== FortuneWheelSliceType.NO_PRIZE
    const slice1Active = formValues[1].type !== FortuneWheelSliceType.NO_PRIZE
    const slice2Active = formValues[2].type !== FortuneWheelSliceType.NO_PRIZE

    /* Special scenario that depends on which slices are active */
    // If only reward 0 is active
    // update slices 0, 2, 4, 6, 8, 10 with that value (Even numbered slices will be “sorry, next time!”/no prize slices)
    if (slice0Active && !slice1Active && !slice2Active) {
      this.slices.forEach((slice, i) => {
        if ([0, 2, 4, 6, 8, 10].includes(i)) {
          this.slices[i] = {
            ...slice,
            reward: formValues[0].reward,
            gravity: formValues[0].gravity,
            label: formValues[0].label,
            type: formValues[0].type,
          }
        } else {
          this.slices[i] = {
            ...slice,
            reward: {} as any,
            gravity: 0,
            label: 'Sorry, next time!',
            type: FortuneWheelSliceType.NO_PRIZE,
          }
        }
      })
    }
    // If only reward 0 and reward 1 are active or only reward 0 and reward 2 are active
    // update slice 0, 4, 8 with reward 0
    // update slice 2, 6, 10 with reward 1
    if (slice0Active && slice1Active && !slice2Active
      || slice0Active && !slice1Active && slice2Active) {
      this.slices.forEach((slice, i) => {
        if ([0, 4, 8].includes(i)) {
          this.slices[i] = {
            ...slice,
            reward: formValues[0].reward,
            gravity: formValues[0].gravity,
            label: formValues[0].label,
            type: formValues[0].type,
          }
        } else if ([2, 6, 10].includes(i)) {
          this.slices[i] = {
            ...slice,
            reward: formValues[1].reward,
            gravity: formValues[1].gravity,
            label: formValues[1].label,
            type: formValues[1].type,
          }
        } else {
          this.slices[i] = {
            ...slice,
            reward: {} as any,
            gravity: 0,
            label: 'Sorry, next time!',
            type: FortuneWheelSliceType.NO_PRIZE,
          }
        }
      })
    }
    // If all rewards are active
    // update slice 0, 6 with reward 0
    // update slice 2, 8 with reward 1
    // update slice 4, 10 with reward 2
    if (slice0Active && slice1Active && slice2Active) {
      this.slices.forEach((slice, i) => {
        if ([0, 6].includes(i)) {
          this.slices[i] = {
            ...slice,
            reward: formValues[0].reward,
            gravity: formValues[0].gravity,
            label: formValues[0].label,
            type: formValues[0].type,
          }
        } else if ([2, 8].includes(i)) {
          this.slices[i] = {
            ...slice,
            reward: formValues[1].reward,
            gravity: formValues[1].gravity,
            label: formValues[1].label,
            type: formValues[1].type,
          }
        } else if ([4, 10].includes(i)) {
          this.slices[i] = {
            ...slice,
            reward: formValues[2].reward,
            gravity: formValues[2].gravity,
            label: formValues[2].label,
            type: formValues[2].type,
          }
        } else {
          this.slices[i] = {
            ...slice,
            reward: {} as any,
            gravity: 0,
            label: 'Sorry, next time!',
            type: FortuneWheelSliceType.NO_PRIZE,
          }
        }
      })
    }
    const params = {
      ...this.quickSetupService.params$.value,
      slices: this.slices
    }
    this.quickSetupService.params$.next(params)
  }

  couponCreated(index = 0, couponCode) {
    const control = (this.form.get('slices') as FormArray).controls[index]
    if (couponCode && control) {
      control.patchValue({
        label: couponCode.title,
      })
    }
  }

  addEmailAutomationIfNeeded() {
    return this.marketingEmailsApiService.createAutoResponder(this.pluginType, {
      campaign_id: this.quickSetupService.currentCampaign.id,
      enabled: this.form.value.email_automation,
    })
  }

  navigateToCustomSetup() {
    this.quickSetupService.closeDialog$.next(PluginSetupResult.NavigateToSettings)
  }

  publish() {
    this.quickSetupService.quickSetupEvent('Publish Quick Setup')
    this.subscription.add(
      // Add email autoresponder first
      this.addEmailAutomationIfNeeded().pipe(switchMap((email) => {
        return this.quickSetupService.submit(email)
      })).subscribe(res => {
        this.campaignDataService.refreshCampaign()
        this.quickSetupService.loadComponentByPath('end')
      })
    )
  }

  ngOnDestroy(): void {
    // Remove reward_code from params before moving forward, it's being used only for preview
    const params = this.quickSetupService.params$.value as CouponBoxNotificationParams
    delete params.reward_code
    this.quickSetupService.params$.next(params)
    this.subscription.unsubscribe()
  }
}
