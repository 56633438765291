import { Component, Inject } from '@angular/core'
import { CampaignService } from '../../../../../../core/services/campaign.service'
import { CampaignPluginName } from '../../../../../models/campaign/campaign'
import { WidgetNotificationParams } from '../../../../../models/widget/notification.model'
import { CampaignCardValues } from '../../../models/campaign-cards.model'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'


@Component({
  templateUrl: './card-quickstart-modal.component.html',
  styleUrls: ['./card-quickstart-modal.component.scss']
})
export class CardQuickstartModalComponent {
  cardValues = CampaignCardValues
  preview: WidgetNotificationParams
  socialCards = {
    [CampaignCardValues.RecentSalesPop]: CampaignPluginName.RecentActivity,
    [CampaignCardValues.LiveVisitorCount]: CampaignPluginName.LiveVisitorCount,
    [CampaignCardValues.AggregateSalesPop]: CampaignPluginName.AggregateActivity,
    [CampaignCardValues.AggregateVisitorCount]: CampaignPluginName.AggregateVisitorCount
  }
  variables = {
    accent_color: '#0095f7',
    background_color: '#ffffff',
    image_mask_radius: '2.5px',
    image_padding: '0px',
    notification_height: 'auto',
    notification_margin_right: 'auto',
    notification_width: '360px',
    pulse_shadow_color_end: '#0095F700',
    pulse_shadow_color_start: '#0095F799',
    rounded_radius: '50px',
    shadow_toggle: true,
    subtitle_margin_bottom: '0px',
    subtitle_size: '12px',
    subtitle_weight: '400',
    text_color: '#738c98',
    title_highlight_background: 'rgba(0, 149, 247, 0.05)',
    title_highlight_color: '#0095f7',
    title_highlight_margin_right: '0px',
    title_highlight_padding: '3px',
    title_highlight_radius: '3px',
    title_highlight_size: '14px',
    title_highlight_weight: '700',
    title_margin_bottom: '2px',
    title_size: '14px',
    title_weight: '500',
  }
  styles = '.notification { border-radius: 2.5px; } .button-close { right:5px; }'

  constructor(
    public dialogRef: MatDialogRef<CardQuickstartModalComponent>,
    private campaignService: CampaignService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.socialCards[this.data.card.value]) {
      this.preview = this.campaignService.makeNotificationDummyPreviewParams(this.socialCards[this.data.card.value], this.data.order)
    }
  }
}
