import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'

@Component({
  selector: 'pf-stepper-step',
  templateUrl: './stepper-step.component.html',
  styleUrls: ['./stepper-step.component.scss']
})
export class StepperStepComponent implements OnInit {
  @ViewChild(TemplateRef, {static: true}) template: TemplateRef<any>

  constructor() { }

  ngOnInit() {
  }

}
