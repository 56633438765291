<div class="L-QuickSetup-Content _configure-view">
  <div class="L-QuickSetup-Header">
    <h2 class="L-QuickSetup-Title">Quick Setup</h2>
    <p class="L-QuickSetup-Description">Select which prizes your visitors have a chance to win!</p>
  </div>
  <div class="L-QuickSetup-Preview-Wrap">
    <div class="L-QuickSetup-Preview">
      <pf-fortune-wheel-preview
        [params]="params"
        [withBrowser]="false"
        [showBackground]="false"
      ></pf-fortune-wheel-preview>
    </div>
  </div>
  <div class="L-QuickSetup-Controls">
    <div #controlsRef></div>
  </div>
</div>