<div class="QS">
  <h4 class="QS-Title text-center">
    {{ data.card.title }}
  </h4>
  <div class="QS-Grid">
    <div class="QS-Left">
      <div class="QS-Descr">
        <h6 class="text-center mb-3">
          Quick Setup
        </h6>
        <!-- <div class="QS-TimeTag _left">Instant</div> -->
      </div>
      <div class="QS-Body _preview">
        <ng-container *ngIf="!!socialCards[data.card.value]">
          <pf-preview-notification [data]="preview" [variables]="variables"
                                   [styles]="styles"></pf-preview-notification>
        </ng-container>
      </div>
      <div class="QS-Footer text-center">
        <button class="QS-Right-Button pf-button filled blue mt-3" (click)="dialogRef.close('quickstart')">Publish Now</button>
        <small class="QS-TimeEstimate text-muted mt-2">Instant</small>
      </div>
    </div>
    <div class="QS-Divider">
      <div>
        <span>OR</span>
      </div>
    </div>
    <div class="QS-Right">
      <div class="QS-Descr">
        <h6 class="text-center mb-3">
          Custom Setup
        </h6>
        <!-- <div class="QS-TimeTag _right">{{ data.card.setup_mins }} min setup</div> -->
      </div>
      <div class="QS-Body">
        <div class="QS-Image" [class._sales]="[cardValues.RecentSalesPop, cardValues.AggregateSalesPop].includes(data.card.value)">
        </div>
      </div>
      <div class="QS-Footer text-center">
        <button class="QS-Right-Button pf-button filled green mt-3" (click)="dialogRef.close('custom')">Get Started</button>
        <small class="QS-TimeEstimate text-muted mt-2">{{ data.card.setup_mins }} min setup</small>
      </div>
    </div>
  </div>
</div>
