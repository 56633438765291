import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { ContactUsModalComponent } from '../../shared/components/modals/contact-us.modal/contact-us.modal.component'
import { select, Store } from '@ngrx/store'
import { StoreState } from '../../store/store.state'
import { Subscription } from 'rxjs'
import { getUserShopType } from '../../store/user/user.selectors'
import { UserService } from '../../core/services/user.service'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'pf-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() items
  shopType: string
  subscription = new Subscription()

  constructor(
    private userService: UserService,
    private store: Store<StoreState>,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.subscription.add(this.store
      .pipe(select(getUserShopType))
      .subscribe(next => this.shopType = next))
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    this.dialog.closeAll()
  }

  openModal(e: MouseEvent, modalName: string) {
    e.preventDefault()
    switch (modalName) {
      case 'ContactUsModalComponent':
        this.dialog.open(ContactUsModalComponent, {
          width: '500px',
          data: {
            shop: this.shopType ? this.userService.getShopTypeName(this.shopType) : null
          }
        })
        break
    }
  }
}
