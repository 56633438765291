import {Injectable} from '@angular/core'
import {Observable, Observer} from 'rxjs'

export interface ImageFits {
  fits?: boolean,
  width?: number,
  height?: number,
  size?: number,
  cause?: {
    width: boolean,
    height: boolean,
    fileSize: boolean,
  },
  message?: string
  file?: any
}

@Injectable()
export class FileSizeService {

  /**
   * @param bytes File size in bytes
   * @param decimals Decimals point
   */
  static formatFileSize(bytes: number, decimals = 2) {
    if (bytes == 0) return '0 Bytes';
    const k = 1000,
      dm = decimals,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * @param img Uploaded file
   * @param maxMb Maximum allowed size in Megabytes
   * @param maxPxWidth
   * @param maxPxHeight
   */
  imageFits(img: any, maxMb: number, maxPxWidth: number = null, maxPxHeight: number = null): Observable<ImageFits> {
    return new Observable((observer: Observer<ImageFits>) => {
      const fr = new FileReader
      fr.onload = () => {
        const _img = new Image()
        _img.onload = () => {
          const height = _img.naturalHeight
          const width = _img.naturalWidth
          const size = img.size * 1e-6
          const cause = {
            width: maxPxWidth ? width > maxPxWidth : false,
            height: maxPxHeight ? height > maxPxHeight : false,
            fileSize: size > maxMb
          }
          const fits = !cause.fileSize && !cause.width && !cause.height
          let message = ''
          if (cause.fileSize) {
            message = 'Uploaded image is too big, it may slow down the speed of your pop up, please use <a href="https://compressor.io/compress" target="_blank">this service</a> to compress it'
          } else if (cause.width) {
            message = `The file must be at most ${maxPxWidth}px in width`
          } else if (cause.height) {
            message = `The file must be at most ${maxPxHeight}px in height`
          }

          observer.next({width, height, size, cause, fits, message})
          observer.complete()
        }
        _img.onerror = err => {
          observer.error(err);
        }
        _img.src = fr.result as string
      }
      fr.readAsDataURL(img)
    })
  }

}
