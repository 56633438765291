import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import _, { isEqual } from 'lodash'
import { fromEvent, Subscription } from 'rxjs'
import { environment } from '../../../../../../environments/environment'
import { UserService } from '../../../../../core/services/user.service'
import {
  ConfigTriggerMonitoringService,
} from '../../../../../shared/modules/triggers-page/config-trigger-monitoring/config-trigger-monitoring.service'
import { NewCouponCodesService } from '../../../../coupon-codes/services/new-coupon-code.service'
import { PreviewService } from '../../../services/preview.service'
import { FortuneWheelParams, FortuneWheelPreviewParams } from '../models/fortune-wheel.model'
import { FortuneWheelService } from '../services/fortune-wheel.service'
import { IframeHelperService } from '../../../../../shared/services/iframe.service'
import { IframeEventType } from '../../../../../shared/modules/triggers-page/models/trigger-events.model'
import { PreviewState } from '../../../../../shared/modules/preview-toggle/models/preview-state'
import { CampaignPluginName } from '../../../../../shared/models/campaign/campaign'

// import * as logRocketJs from '!raw-loader!../../../../../../app_assets/scripts/LogRocket.min.js'

@Component({
  selector: 'pf-fortune-wheel-preview',
  templateUrl: './fortune-wheel-preview.component.html',
  styleUrls: ['./fortune-wheel-preview.component.scss']
})
export class FortuneWheelPreviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() params: FortuneWheelParams | any
  @Input() fullVersion: boolean
  @Input() showBackground: boolean = true
  @Input() thumbPreview: boolean = false
  @Input() isTriggersStep: boolean = false
  @Input() withBrowser: boolean = false
  @Input() scrolling: 'yes' | 'no' | 'auto' = 'no'
  @Input() disablePopupScale = false
  @Input() fullScreenPreview = false
  @Output() iframeLoaded = new EventEmitter<string>()
  @Output() iframeData = new EventEmitter<any>()
  @HostBinding('class._has-background') hasBackground: boolean = true
  @HostBinding('class._is-fullversion') isFullVersion: boolean = true
  @ViewChild('iFrame', {static: true}) iFrameRef: ElementRef
  subscription: Subscription = new Subscription()

  production = environment.production
  notLoaded: boolean = true
  doc: any
  overlayBg: any
  previewData: { params: FortuneWheelPreviewParams }
  previewElement = null
  previewClass = {'proof-factor-wheel-preview': true}
  iFrameSRC = null
  sizes: any

  constructor(
    public elementRef: ElementRef,
    public userService: UserService,
    public previewService: PreviewService,
    private newCouponCodesService: NewCouponCodesService,
    private fortuneWheelService: FortuneWheelService,
    private monitoringTriggerService: ConfigTriggerMonitoringService,
    private iframeHelperService: IframeHelperService
  ) {
  }

  ngOnInit(): void {
    this.previewService.previewDevice = this.previewService.devicePreviewButtons[0]
    this.hasBackground = this.showBackground
    this.isFullVersion = this.fullVersion
    this.updateiFrameSRC()
    window.document.addEventListener('PFWidgetEvent', (data: CustomEvent) => {
      this.iframeData.emit(data)
      // reset preview state btns to default when teaser was clicked
      if (data.detail?.type === IframeEventType.PFTriggerButtonClick) {
        this.previewService.previewState = PreviewState.default
      }
    }, false)
    this.subscription.add(
      this.previewService.previewState$.asObservable().subscribe(value => {
        this.updatePreviewParams()
      })
    )
    this.subscription.add(
      this.previewService.previewDevice$.asObservable().subscribe(value => {
        this.updatePreviewSizes()
      })
    )
    this.subscription.add(
      this.fortuneWheelService.sliceHovered$.asObservable().subscribe(value => {
        this.iFrameRef.nativeElement.contentWindow.postMessage({
          src: 'one-dashboard',
          type: 'sliceHovered',
          value: value,
        }, '*')
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.previewData) {
      this.previewData = {} as any
    }
    if (changes.params && changes.params.currentValue) {
      const wheelParams: FortuneWheelParams = _.cloneDeep(changes.params.currentValue)
      const previewSlices = _.get(wheelParams, 'slices', [])
      const slicesConfig = _.get(wheelParams, 'config.slices_config')
      const referenceToken = this.newCouponCodesService.generateRandomCode().substring(3).toLowerCase()
      const previewParams: FortuneWheelPreviewParams = {
        active: wheelParams.active,
        slices: wheelParams.slices,
        texts: wheelParams.config.texts,
        form_config: wheelParams.config.form_config,
        display: wheelParams.config.display,
        theme: wheelParams.config.theme,
        branding: wheelParams.config.branding,
        advanced: wheelParams.config.advanced,
        resultId: null,
        onSpin: () => {
          previewParams.resultId = previewSlices[Math.floor(1 + Math.random() * 12)].id
          this.updatePreviewParams()
        },
        previewMode: true,
        previewModeLoss: slicesConfig.allow_loss,
        previewIframe: true,
        detectedCountry: this.userService.getUserCountry(),
        disablePopupScale: this.previewService.previewDevice.isMobile && this.disablePopupScale,
        isPhonePreview: this.previewService.previewDevice.isMobile,
        isSlicesPreview: this.previewService.previewDevice.isSlicesPreview,
        previewState: this.previewService.previewState,
        showFullVersion: this.fullVersion,
        reference_token: referenceToken,
        required_option: wheelParams.config.required_option,
        required_option_error_text: wheelParams.config.required_option_error_text,
        send_coupon_code: wheelParams.config.send_coupon_code,
        send_gift_card_code: wheelParams.config.send_gift_card_code,
        elements_styling: wheelParams.config?.elements_styling,
      }
      this.previewService.popupParams = wheelParams

      if (!isEqual(this.previewData.params, previewParams)) {
        this.previewData.params = previewParams
        this.overlayBg = this.previewData?.params?.theme?.variables?.overlay_bg
        this.updatePreviewParams()
      }
    }

    if (window['PROOF_FACTOR_PREVIEW_LOADED']) {
      this.notLoaded = false
    }
  }

  updateiFrameSRC() {
    const getGeneratedPageURL = ({html}) => {

      // <script>${logRocketJs.default}</script>
      // <script>
      //   LogRocket.init('morpheus/one', {
      //     rootHostname: 'one.store',
      //     mergeIframes: true,
      //     parentDomain: "${window.location.origin}"
      //   })
      // </script>
      const source = `
        <html>
          <head>
          </head>
          <body style="margin: 0; padding: 0; border: 0;">
            ${html || ''}
          </body>
        </html>
      `

      return this.iframeHelperService.getIframeUrl(this.iFrameRef, source)
    }

    this.iFrameSRC = getGeneratedPageURL({
      html: `<proof-factor-wheel-preview id="proof-factor-wheel-preview"></proof-factor-wheel-preview>
             <script type="application/javascript" src="${
        environment.widgetPreviewURL
      }?timestamp=${+new Date()}" id="widget-script" data-source="dashboard"></script>`,
    })
  }

  onLoad() {
    if (this.iFrameRef) {
      this.doc = this.iFrameRef.nativeElement.contentDocument || this.iFrameRef.nativeElement.contentWindow
      this.previewElement = this.doc.getElementById('proof-factor-wheel-preview')
      this.updatePreviewParams()
      this.iframeLoaded.emit('loaded')
      if (this.isTriggersStep) {
        this.trackScrollEvent()
      }
    }
  }

  trackScrollEvent() {
    const iFrameWindow = this.iFrameRef.nativeElement.contentWindow
    this.subscription.add(
      fromEvent(iFrameWindow, 'scroll').subscribe(() => {
        const scrollableHeight = iFrameWindow.document.body.scrollHeight - iFrameWindow.innerHeight
        const percentScrolled = Math.round(
          (100 * iFrameWindow.pageYOffset) / scrollableHeight,
        )
        this.monitoringTriggerService.scrollNext = percentScrolled
      }),
    )
  }

  updatePreviewParams() {
    if (this.previewElement && this.previewData.params) {
      this.previewElement.params = {
        ...this.previewData.params,
        ...{
          disablePopupScale: this.previewService.previewDevice.isMobile && this.disablePopupScale,
          isPhonePreview: this.previewService.previewDevice.isMobile,
          isSlicesPreview: this.previewService.previewDevice?.isSlicesPreview,
          previewState: this.previewService.previewState,
          detectedCountry: this.userService.getUserCountry(),
          showFullVersion: this.fullVersion,
          withBrowser: this.withBrowser,
        },
      }
    }
  }

  toPx(value: number) {
    return !isNaN(value) ? `${value.toFixed(0)}px` : null
  }

  updatePreviewSizes() {
    const _sizes = {}

    if (!this.previewService.previewDevice) {
      return false
    }

    if (!this.thumbPreview) {
      _sizes['width'] = this.fullScreenPreview ? '100%' : this.previewService.previewDevice.width
      _sizes['min-width'] = this.fullScreenPreview ? '100%': this.previewService.previewDevice.minWidth

      _sizes['height'] = this.fullScreenPreview ? '100%': this.previewService.previewDevice.height
      _sizes['min-height'] = this.fullScreenPreview ? '100%': this.previewService.previewDevice.minHeight

      this.sizes = _sizes
      this.updatePreviewParams()
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
