import { AfterContentInit, Component, ContentChildren, QueryList} from '@angular/core'
import { StepperStepComponent } from '../stepper-step/stepper-step.component'

@Component({
  selector: 'pf-stepper-content',
  templateUrl: './stepper-content.component.html',
  styleUrls: ['./stepper-content.component.scss']
})
export class StepperContentComponent implements AfterContentInit {
  @ContentChildren(StepperStepComponent) inputSteps: QueryList<StepperStepComponent>
  public steps: StepperStepComponent[]
  constructor() { }

  ngAfterContentInit() {
    this.inputSteps.forEach(tab => {
      console.log(tab.template);
    });
    this.steps = this.inputSteps.toArray();
  }

}
