<pf-promotion-cards
  *ngIf="showFeature('promotion-cards')"
  [showHolidayCards]="false"
  [showFeaturedApps]="true">
</pf-promotion-cards>
<div class="AppsCards-Wrapper" [class._has-header]="showFeature('search-bar')">
  <div class="_local-header mb-4">
    <h1 >Which app do you want to set up next?</h1>
    <button *ngIf="!this.isOnboarding" (click)="watchVideoClicked()" class="pf-button outline purple">
      <one-icon name="videocam-outline"></one-icon>
      Introduction
    </button>
  </div>
  <!-- <div class="AppsCards-Header mt-1" *ngIf="showFeature('search-bar')">
    <div class="AppsCards-Header-Grid">
      <div class="AppsCards-Header-Cell">
        <h4 class="AppsCards-Title">How do you want to increase sales?</h4>
        <div class="AppsCards-Search form-group">
          <one-icon name="search"></one-icon>
          <input type="text" class="form-control" placeholder="Type keyword"
                 (keydown.enter)="createKeyword($event)">
        </div>
        <div class="AppsCards-Tags">
          <ng-container *ngFor="let tag of selectedTags">
            <span class="AppsCards-Tag" [class._active]="tag.selected" (click)="selectTag(tag)" (mouseover)="tag.hover=true" (mouseout)="tag.hover=false">
              <one-icon *ngIf="tag.selected"  [name]="tag.hover ? 'close-circle': 'checkmark-circle'"></one-icon>
              {{ tag.label }}
            </span>
          </ng-container>
        </div>
      </div>

      <pf-message-box
        messageType="success" class="VIP-Message"
        [customStyle]="{'border-radius': '5px', 'margin-bottom': '12px'}"
        [messageHtml]="'Need a demo?\<br>Try our \<b>Free VIP Setup\</b>'"
        buttonText="Schedule Call" (buttonClick)="openCalendlyLink()"
        buttonClass="pf-button green outline"
      ></pf-message-box>
    </div>
  </div> -->

  <ng-container *ngIf="showFeature('onboarding-header')">
    <div class="AppsCards-Header text-center">
      <h2 class="AppsCards-Title">What do you want to set up first?</h2>
    </div>
  </ng-container>

  <div class="AppsCards-Main">
    <div class="AppsCards-Grid">
      <ng-container *ngFor="let card of campaignCards">
        <one-quick-setup-card
          [card]="card"
          (openCrisp)="openCrisp($event)"
          (openCard)="openCard($event)"
        >
        </one-quick-setup-card>
      </ng-container>
    </div>

  </div>
</div>

<div class="AppsCards-Wrapper" *ngIf="showFeature('non-shopify')">
  <div class="_local-header mb-4">
    <h1>Only Available for Shopify Shops</h1>
  </div>

  <div class="AppsCards-Main">
    <div class="AppsCards-Grid">
      <ng-container *ngFor="let card of shopifyCampaignCards">
        <one-quick-setup-card
          [viewOnlyCard]="true"
          [card]="card"
          (openCard)="openCard($event, true)"
        >
        </one-quick-setup-card>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="showFeature('support-boxes')">
  <pf-support-boxs [hasRequestFeature]="false"></pf-support-boxs>
</ng-container>

<div *ngIf="showFeature('footer-logout')" class="_local-logout">
  <pf-log-out></pf-log-out>
</div>
<pf-loading-overlay [filter]="{ignore: ['CreateCampaignWithPlugin']}"></pf-loading-overlay>
