import { ShopifyService } from './../../../core/services/shopify.service';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import _ from 'lodash'
import { Subscription } from 'rxjs'
import { CampaignDataService } from '../../../core/services/data-services/campaign-data.service'
import { CampaignPluginName } from '../../../shared/models/campaign/campaign'
import { CouponBoxNotificationParams, NewsLetterNotificationParams } from '../../../shared/models/campaign/coupon-box-notification.model'
import { FortuneWheelParams } from '../plugins/fortune-wheel/models/fortune-wheel.model'
import { PluginSetupResult, QuickSetupModalData } from './models/quick-setup.model'
import { QuickSetupService } from './services/quick-setup.service'
import quickSetupSteps from './steps-config'
import { CustomSnackbarService } from '../../../shared/modules/custom-snackbar/custom-snackbar.service'
import { ShopifySubscribersApiService } from '../../subscribers/services/shopify-subscribers-api.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './quick-setup.component.html',
})

export class QuickSetupComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef
  private subscription = new Subscription()
  public campaignPluginName = CampaignPluginName
  public params$ = this.quickSetupService.params$
  public params: FortuneWheelParams | CouponBoxNotificationParams | NewsLetterNotificationParams
  public pluginSetupResult = PluginSetupResult

  public nextButtonVisible$ = this.quickSetupService.nextButtonVisible$
  public backButtonVisible$ = this.quickSetupService.backButtonVisible$
  public submitButtonVisible$ = this.quickSetupService.submitButtonVisible$

  constructor(
    public quickSetupService: QuickSetupService,
    private campaignDataService: CampaignDataService,
    private shopifyService: ShopifyService,
    private shopifySubscribersApiService: ShopifySubscribersApiService,
    public dialogRef: MatDialogRef<QuickSetupComponent>,
    private snackbarService: CustomSnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: QuickSetupModalData,
  ) {
  }

  ngOnInit(): void {
    // Set plugin type
    this.quickSetupService.currentPlugin = this.data.pluginType
    // Set parent container
    this.quickSetupService.parentContainer = this.container
    // Init steps
    this.quickSetupService.steps = quickSetupSteps
    // Set campaign plugin item
    this.quickSetupService.campaignPluginItem = this.data.campaignPluginItem
    this.subscription.add(
      this.campaignDataService.asObservable().subscribe(_campaign => {
        let campaign = _.cloneDeep(_campaign)
        // BCB default override
        if (this.data.pluginType === CampaignPluginName.CouponBoxNotification) {
          this.quickSetupService.setBCBDefaultPromptCopy(campaign.coupon_box_notification, campaign.coupon_box_notification.reward)
        }
        this.quickSetupService.currentCampaign = campaign
      })
    )
    this.subscription.add(
      this.shopifySubscribersApiService.getContactsStatisticsField('active_known_customers_total')
      .subscribe(res => {
        this.quickSetupService.akcsTotal$.next(res)
      })
    )
    this.subscription.add(
      this.quickSetupService.fetchTheme().subscribe(res => {
        this.quickSetupService.currentTheme = res as any
        if (this.quickSetupService.currentTheme) {
          this.quickSetupService.updateTheme(this.data.pluginType)
          this.quickSetupService.saveDefaultParams()
          this.renderComponent()
        } else {
          this.renderComponent()
          console.error('Theme not found with identifier: ' + this.quickSetupService.defaultThemes[this.data.pluginType] + ' for plugin: ' + this.data.pluginType)
        }
      }, err => {
        this.renderComponent()
        console.error('Unable to fetch theme', err)
      })
    )

    this.subscription.add(
      this.quickSetupService.closeDialog$.asObservable().subscribe(params => {
        this.dialogRef.close(params)
      })
    )

    // Listening for component loaded, to update footer buttons
    // this.subscription.add(
    //   this.quickSetupService.componentLoaded$.subscribe(() => {
    //     this.quickSetupService.updateFooterButtons(this.data.pluginType)
    //   })
    // )
  }

  renderComponent(): void {
    // Load popup's parent component
    this.quickSetupService.loadComponent(this.quickSetupService.steps.component)
  }

  // onNextClick(): void {
  //   this.quickSetupService.loadNextStep(this.data.pluginType)
  // }

  // onBackClick(): void {
  //   this.quickSetupService.loadPrevStep(this.data.pluginType)
  // }

  onPublishClick(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
