<div class="L-QuickSetup-Content _configure-view">
  <div class="L-QuickSetup-Header">
    <h2 class="L-QuickSetup-Title">Quick Setup</h2>
    <p class="L-QuickSetup-Description">Select if you want to automatically send a confirmation email after your visitor subscribes.</p>
  </div>
  <div class="L-QuickSetup-Preview-Wrap">
    <div class="L-QuickSetup-Preview">
      <pf-newsletter-preview
        [params]="params"
        [withBrowser]="false"
        [showBackground]="false"
      ></pf-newsletter-preview>
    </div>
  </div>
  <div class="L-QuickSetup-Controls">
    <div #controlsRef></div>
  </div>
</div>