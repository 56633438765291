import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { Subscription } from 'rxjs'
import { CampaignPluginName } from '../../../../../shared/models/campaign/campaign'
import { CouponBoxNotificationParams } from '../../../../../shared/models/campaign/coupon-box-notification.model'
import { QuickSetupService } from '../../services/quick-setup.service'

@Component({
  templateUrl: './quick-setup-coupon-box.component.html',
})

export class QuickSetupCouponBoxComponent implements OnInit, OnDestroy {
  @ViewChild('controlsRef', { read: ViewContainerRef, static: true }) controlsRef: ViewContainerRef 
  private subscription = new Subscription()
  pluginType = CampaignPluginName.CouponBoxNotification
  public params: CouponBoxNotificationParams

  constructor(
    public quickSetupService: QuickSetupService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.quickSetupService.params$.subscribe(params => {
        this.params = {...params} as CouponBoxNotificationParams
      })
    )
    
    // Set child container
    this.quickSetupService.childContainer = this.controlsRef
    // Load first step
    this.quickSetupService.loadChildStep('inputs')
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}
