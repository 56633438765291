<button class="mat-dialog-close d-none" (click)="dialogRef.close()">
  <span>&times;</span>
</button>
<mat-dialog-content class="CN">
  <div>
    <ng-container *ngIf="data && !!data.children">
      <div *ngIf="!data.hide_title" class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="CN-Title">{{ data.title }}</div>
          <ng-container
            *ngIf="data.hasTutorial"
            [ngTemplateOutlet]="tutorialModal"
            [ngTemplateOutletContext]="{ preset: data }">
          </ng-container>
      </div>
      <div class="CN-Body">
        <ng-container *ngFor="let preset of data.children">

          <ng-container *ngIf="preset.type && preset.type === 'separator'; else cardInToolbox">
            <div class="d-flex justify-content-between align-items-center flex-wrap">
              <div class="CN-Title">{{ preset.title }}</div>
                <ng-container
                  *ngIf="preset.hasTutorial"
                  [ngTemplateOutlet]="tutorialModal"
                  [ngTemplateOutletContext]="{ preset: preset }">
                </ng-container>
            </div>
          </ng-container>
          <ng-template #cardInToolbox>

            <ng-container *ngIf="preset.inToolbox; else cardView">
              <div class="PluginConfig-DesignCard _local-card">
                <h5 class="PluginConfig-Subtitle _hoverable"
                    [class._invert]="preset.toolboxOpen"
                    (click)="preset.toolboxOpen = !preset.toolboxOpen">
                  <div class="sticker"></div>
                  <one-icon name="chevron-up"></one-icon>
                  {{ preset.toolboxTitle }}
                </h5>
                <ng-container *ngIf="preset.toolboxOpen">
                  <div class="PluginConfig-Line _local-line">
                    <ng-container *ngFor="let preset of preset.toolboxChildren">
                      <div class="CN-Promo">
                        <div class="CN-Promo-Image"
                             [style.background]="getBgImage(preset.img)"
                             [style.backgroundSize]="'cover'"
                             [style.backgroundPosition]="'center'">
                        </div>
                        <span class="CN-Promo-Right">
                          <div>
                            <h4 class="mb-0">{{ preset.title }}</h4>
                            <ng-container *ngIf="preset.badges_visible">
                              <div *ngFor="let badge of preset.badges" class="pf-badge {{badge.color}} mr-1">
                                {{ badge.text }}
                              </div>
                            </ng-container>
                            <p>{{ preset.description }}</p>
                          </div>
                          <div *ngIf="!data.viewOnly" class="CN-Promo-Footer">
                            <button class="pf-button filled blue" *ngIf="!preset.locked; else lockedButton"
                                    (click)="closeDialog(preset)">
                              {{ preset.button_text || 'Create' }}
                            </button>
                            <ng-template #lockedButton>
                              <button class="pf-button filled black sm"
                                      (click)="closeDialog(preset)">
                                <one-icon class="mr-1" name="lock-closed-outline"></one-icon>
                                {{ preset.locked_text || 'Unlock' }}
                              </button>
                            </ng-template>
                          </div>
                         </span>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-template>
          <ng-template #cardView>
            <div class="CN-Promo">
              <div class="CN-Promo-Image"
                  [style.background]="getBgImage(preset.img)"
                  [style.backgroundSize]="'cover'"
                  [style.backgroundPosition]="'center'">
              </div>
              <span class="CN-Promo-Right">
                <div>
                  <h4 class="mb-0">{{ preset.title }}</h4>
                  <ng-container *ngIf="preset.badges_visible">
                    <div *ngFor="let badge of preset.badges" class="pf-badge {{badge.color}} mr-1">
                      {{ badge.text }}
                    </div>
                  </ng-container>
                  <p>{{ preset.description }}</p>
                </div>
                <div *ngIf="!data.viewOnly" class="CN-Promo-Footer">
                  <button class="pf-button filled blue" *ngIf="!preset.locked; else lockedButton"
                          (click)="closeDialog(preset)">
                    {{ preset.button_text || 'Create' }}
                  </button>
                  <ng-template #lockedButton>
                    <button class="pf-button filled black sm"
                            (click)="closeDialog(preset)">
                      <one-icon name="lock-closed-outline" class="mr-1"></one-icon>
                      {{ preset.locked_text || 'Unlock' }}
                    </button>
                  </ng-template>
                </div>
              </span>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
<ng-template #tutorialModal let-preset="preset">
  <ng-container>
    <!-- <pf-video-tutorial-gallery
      *ngIf="preset?.videoTutorial?.tutorialType === 'library'"
      class="video-tutorial-button"
      [segment]="preset?.videoTutorial?.tutorialSegment"
      [currentVideo]="preset?.videoTutorial?.currentVideo"
      buttonType="filled"
    ></pf-video-tutorial-gallery>
    <button
      *ngIf="preset?.videoTutorial?.tutorialType === 'modal'"
      class="one-button sm filled purple video-tutorial-button"
      (click)="showTutorialModal(preset?.videoTutorial?.currentVideo)"
    >
      <one-icon name="videocam-outline"></one-icon>
      Tutorial
    </button> -->
    <a class="one-button sm purple outline mr-2"
      (click)="navigateToTutorials(preset)">
      <one-icon name="videocam-outline"></one-icon>
      Tutorial
    </a>
  </ng-container>

</ng-template>
