import { CampaignPluginName } from "../../../../shared/models/campaign/campaign"
import { PluginsComponentAvailablePlugin } from "../../campaign/campaign-plugins/campaign-plugins.component"

export interface QuickSetupModalData {
  pluginType: CampaignPluginName
  campaignPluginItem: PluginsComponentAvailablePlugin
}

export interface QuickSetupStep {
  path: string
  component: any
  children?: QuickSetupStep[]
}

export enum PluginSetupResult {
  CustomSetup = 'custom-setup',
  NavigateToSettings = 'navigate-to-settings',
  Closed = 'closed',
  NotNow = 'not-now',
}
