import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { Subscription } from 'rxjs'
import { CampaignPluginName } from '../../../../../shared/models/campaign/campaign'
import { FortuneWheelParams } from '../../../plugins/fortune-wheel/models/fortune-wheel.model'
import { QuickSetupService } from '../../services/quick-setup.service'

@Component({
  templateUrl: './quick-setup-fortune-wheel.component.html',
})
export class QuickSetupFortuneWheelComponent implements OnInit, OnDestroy {
  @ViewChild('controlsRef', { read: ViewContainerRef, static: true }) controlsRef: ViewContainerRef
  private subscription = new Subscription()
  pluginType = CampaignPluginName.FortuneWheel
  public params: FortuneWheelParams

  constructor(
    private quickSetupService: QuickSetupService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.quickSetupService.params$.subscribe(params => {
        this.params = {...params} as FortuneWheelParams
      })
    )
    // Set child container
    this.quickSetupService.childContainer = this.controlsRef
    // Load first step
    this.quickSetupService.loadChildStep('inputs')
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
