<h1 mat-dialog-title>
  {{ this.data.title ? this.data.title : 'Enter Phone' }}
  <button class="mat-dialog-close" (click)="dialogRef.close()">
    <span>&times;</span>
  </button>
</h1>
<div class="mat-dialog-content" [formGroup]="form">
  <ng-container *ngIf="form.get('phone'); let control">
    <pf-phone-input
      [invalid]="control.invalid && control.dirty"
      formControlName="phone"
    ></pf-phone-input>
    <div class="text-danger pl-1" *ngIf="control.dirty && control.errors; let errors">
      <small *ngIf="errors.required">
        Phone Number can't be blank.
      </small>
      <small *ngIf="errors.pattern">
        «{{errors.pattern.actualValue}}» Is not a valid Phone Number.
      </small>
    </div>
  </ng-container>
</div>
<ng-container *ngIf='!data.bypassCaptcha'>
  <div class="py-1">
    <ng-hcaptcha (verify)="onVerify($event)" (expired)="onExpired($event)" (error)="onError($event)" size="compact"
      [siteKey]='environment.hCaptchaSiteKey'>
    </ng-hcaptcha>
  </div>
  <div *ngIf="showError && !captchaToken" class="invalid-feedback d-block">
    Please check the captcha.
  </div>
</ng-container>
<div mat-dialog-actions class="mb-0 mt-2 d-flex justify-content-end">
  <button class="pf-button outline md gray" (click)="dialogRef.close()">Cancel</button>
  <button class="pf-button filled md blue ml-3" (click)="submit()">
    {{ this.data.acceptButton ? this.data.acceptButton : 'Send' }}
  </button>
</div>

<pf-loading-overlay></pf-loading-overlay>
