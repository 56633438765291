<h1 mat-dialog-title class="CL-Title text-center">
  Congratulations!
  <button class="mat-dialog-close" (click)="dialogRef.close(true)">
    <span>&times;</span>
  </button>
</h1>
<div mat-dialog-content class="text-center mb-1">
  <p>
    <b *ngIf="brandingName">{{brandingName  === 'ONE' ? 'ONE+' : brandingName}}</b>
    Scholarship Application Submitted
  </p>
  <img src="/app_assets/campaigns-list/success.png" alt="" class="CL-Image mb-2">
  <pf-message-box
    *ngIf="infoNote"
    messageType="info"
    [customStyle]="{'border-radius': '5px', 'margin-bottom': '12px', 'box-shadow': '0 2px 12px 0 #c4c8d0', 'text-align': 'left', 'padding-right': '10px', 'font-size': '15px'}"
    [messageHtml]="infoNote"
  ></pf-message-box>
</div>
<div mat-dialog-actions class="text-center m-0 d-block">
  <button class="pf-button filled md blue" (click)="dialogRef.close(true)">Get Started</button>
</div>
