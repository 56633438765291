import { Component, OnInit } from '@angular/core';
import { CampaignPluginName } from '../../../../../../shared/models/campaign/campaign';
import { PluginSetupResult } from '../../../models/quick-setup.model';
import { QuickSetupService } from '../../../services/quick-setup.service';

@Component({
  templateUrl: './quick-setup-start.component.html',
  styleUrls: ['./quick-setup-start.component.scss']
})
export class QuickSetupStartComponent implements OnInit {
  public pluginType = this.quickSetupService.currentPlugin
  public campaignPluginName = CampaignPluginName
  constructor(
    private quickSetupService: QuickSetupService,
  ) { }

  ngOnInit(): void {
    this.quickSetupService.quickSetupEvent('Viewed Quick vs Custom Setup Modal')
  }

  startQuickSetup() {
    this.quickSetupService.quickSetupEvent('Select Quick Setup')
    this.quickSetupService.loadComponentByPath(this.quickSetupService.currentPlugin)
  }

  startCustomSetup() {
    this.quickSetupService.closeDialog$.next(PluginSetupResult.CustomSetup)
  }

}
