
<div [formGroup]="form" *ngIf="form" class="QuickSetup-Controls">
  <ng-container [ngSwitch]="pluginType">

    <ng-container *ngSwitchCase="campaignPluginName.CouponBoxNotification">
      <div class="mt-3">
        <pf-reward-select
          [rewardCodeList]="couponCodesResponse.rewards"
          [rewardType]="rewardsType.coupon_code"
          [clearable]="false"
          dropdownPosition="bottom"
          (change)="onBCBRewardUpdate($event)"
          formControlName="coupon_id"
          [itemsPerPage]="25"
          [itemsQuantity]="couponCodesResponse.total_count"
          [noHeader]="true"
        ></pf-reward-select>
      </div>
      <div class="d-flex align-items-center mt-3 mb-4">
        <label class="checkbox">
          <input type="checkbox" formControlName='email_automation'>
          <span></span>
          <span>
            Enable email autoresponder
          </span>
        </label>
        <div>
          <img alt=""
            style="width: 22px; margin-left: 5px;"
            src="./app_assets/icons/message-info-icon.svg"
            matTooltipClass="Tooltip-Box" matTooltipPosition="above"
            matTooltip="Send an automated email to the customer immediately after they subscribe, to thank them for subscribing and provide them their reward code.">
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="campaignPluginName.FortuneWheel">
      <ng-container formArrayName="slices">
        <ng-container *ngFor="let slice of form.get('slices')['controls']; let i = index">
          <div [formGroup]="slice" class="mb-4">
            <p class="mb-2">Reward {{i + 1}}</p>
            <div class="PluginConfig-Radio mb-2">
              <div class="PluginConfig-Radio-Item">
                <input type="radio"
                      formControlName="gravity"
                      id="{{'coupon_code_radio' + i}}"
                      (change)="updateFWSlices()"
                      [value]="10">
                <label for="{{'coupon_code_radio' + i}}">
                  <span class="checkmark"></span>
                  Can Win
                </label>
              </div>
              <div class="PluginConfig-Radio-Item" [class.disabled]="i === 0">
                <input type="radio"
                      formControlName="gravity"
                      id="{{'no_prize_radio' + i}}"
                      (change)="updateFWSlices()"
                      [value]="0">
                <label for="{{'no_prize_radio' + i}}">
                  <span class="checkmark"></span>
                  Cannot Win
                </label>
              </div>
            </div>

            <ng-container formGroupName="reward">
              <pf-reward-select
                [rewardCodeList]="couponCodesResponse.rewards"
                [rewardType]="rewardsType.coupon_code"
                [short]="true"
                [appendTo]="'body'"
                (change)="onFWRewardUpdate(i, $event)"
                (rewardCreated)="couponCreated(i, $event)"
                [itemsPerPage]="25"
                [clearable]="false"
                [itemsQuantity]="couponCodesResponse.total_count"
                [noHeader]="true"
                [expiredCoupon]="slice.value.code_status === 'expired'"
                formControlName="id"
                class="rewardSelectCoupon"
              ></pf-reward-select>
            </ng-container>

          </div>
        </ng-container>
      </ng-container>
      <small class="mb-2">By default, visitors have an equal chance of winning these prizes.</small>
      <div class="d-flex align-items-center mt-3 mb-4">
        <label class="checkbox">
          <input type="checkbox" formControlName='email_automation'>
          <span></span>
          <span>
            Enable email autoresponder
          </span>
        </label>
        <div>
          <img alt=""
            style="width: 22px; margin-left: 5px;"
            src="./app_assets/icons/message-info-icon.svg"
            matTooltipClass="Tooltip-Box" matTooltipPosition="above"
            matTooltip="Send an automated email to the customer immediately after they subscribe, to thank them for subscribing and provide them their reward code.">
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="campaignPluginName.NewsLetterNotification">
      <div class="d-flex align-items-center mt-3 mb-4">
        <label class="checkbox">
          <input type="checkbox" formControlName='email_automation'>
          <span></span>
          <span>
            Enable email autoresponder
          </span>
        </label>
        <div>
          <img alt=""
            style="width: 22px; margin-left: 5px;"
            src="./app_assets/icons/message-info-icon.svg"
            matTooltipClass="Tooltip-Box" matTooltipPosition="above"
            matTooltip="Send an automated email to the customer immediately after they subscribe, to thank them for subscribing.">
        </div>
      </div>
    </ng-container>

  </ng-container>

  <div class="QuickSetup-Controls-Submit" [class.justify-content-end]="!showCustomSetupButton">

    <button *ngIf="showCustomSetupButton"
      class="pf-button filled transparent md mr-2"
      (click)="navigateToCustomSetup()">
      Custom Setup
    </button>

    <div [class.w-100]="showCustomSetupButton" [class.w-50]="!showCustomSetupButton">
      <button class="pf-button filled green md w-100" (click)="publish()">
        Publish
      </button>
      <div *ngIf="!showCustomSetupButton" class="text-muted text-center mt-1 fz12">You can customize later!</div>
    </div>

  </div>
</div>
