<div class="L-QuickSetup">
  <div class="L-QuickSetup-Close" (click)="dialogRef.close(pluginSetupResult.Closed)">
    <one-icon name="close-outline"></one-icon>
  </div>
  <pf-loading-overlay [isFixed]="false"></pf-loading-overlay>
  <div #container></div>
<!-- 
  <div class="L-QuickSetup-Footer">
    <button *ngIf="backButtonVisible$ | async" class="pf-button outline blue md mr-3" (click)="onBackClick()">Back</button>
    <button *ngIf="nextButtonVisible$ | async" class="pf-button filled blue md mr-0 ml-auto" (click)="onNextClick()">Next</button>
    <button *ngIf="submitButtonVisible$ | async" class="pf-button filled green md mr-0 ml-auto" (click)="onPublishClick()">Publish</button>
  </div> -->
  
</div>
