<a *ngIf="isShopifyEmbedded && !isMobile && (isFullscreen$ | async) === true"
  class="PrePlans-fs-button _exit"
  href="#"
  (click)="exitFullScreen($event)">
  <one-icon class="Exit-Fullscreen-icon" src="/app_assets/icons/shopify-exit-fs.svg"></one-icon>&nbsp;
  <span> Back </span>
</a>
<a *ngIf="isShopifyEmbedded && !isMobile && (isFullscreen$ | async) === false"
  href="#"
  class="PrePlans-fs-button _enter"
  (click)="enterFullScreen($event)">
  <one-icon name="expand-outline"></one-icon>&nbsp;
  Full Screen
</a>
<div id="jotform-script"></div>
<div class="JF-Container" *ngIf="!loading">
    <!-- <div class="JF-Logo" *ngIf="activeForm.welcomePage && activeForm.welcomePage.active">
        <img [src]="activeForm.logo" alt="">
    </div>
    <div class="JF-Welcome" *ngIf="activeForm.welcomePage && activeForm.welcomePage.active">
        <div class="JF-Welcome-Title" *ngIf="activeForm.welcomePage.title">
            {{ activeForm.welcomePage.title }}
        </div>
        <div class="JF-Welcome-Subtitle" *ngIf="activeForm.welcomePage.subTitle">
            {{ activeForm.welcomePage.subTitle }}
        </div>
        <div class="JF-Welcome-QuestionCount" *ngIf="activeForm.welcomePage.showQuestionCount && activeForm.welcomePage.questionCountText">
            {{ activeForm.welcomePage.questionCountText}}
        </div>
        <button class="JF-Welcome-Button pf-button blue filled" (click)="startQuestionnaire()">
            {{ activeForm.welcomePage.buttonText || 'START' }}
          <one-icon name="arrow-forward-outline" class="ml-1"></one-icon>
        </button>
    </div> -->
    <div class="JF-Questions" [ngClass]="this.activeForm.class" *ngIf="(!activeForm.welcomePage || !activeForm.welcomePage.active) && activeForm.questions">
        <ng-container *ngFor="let question of activeForm.questions; let first = first; let last = last; let index = index">
            <div class="JF-Question" *ngIf="question.active">
                <div class="JF-Question-Body">
                    <div class="JF-Question-Text" [class.text-danger]="question.error || (question.control && question.control.touched && question.control.errors !== null)">
                        {{ question.text }}<span class="text-danger" *ngIf="question.required">*</span>
                    </div>
                    <div class="JF-Question-Description" *ngIf="question.description">
                        {{ question.description }}
                    </div>
                    <div class="JF-Question-Control">
                        <ng-container [ngSwitch]="question.type">
                            <ng-container *ngSwitchCase="questionType.ControlTextarea">
                                <div class="JF-Question-Control-Textarea" [class._error]="question.control.touched && question.control.errors !== null">
                                    <textarea id="" rows="6" [placeholder]="question.hint" [formControl]="question.control"></textarea>
                                    <span class="_count" *ngIf="question.entryLimitMin">{{ question.control.value.length }}/{{question.entryLimitMin}}</span>
                                </div>
                                <div *ngIf="question.control.touched && question.control.errors">
                                    <small class="text-danger" *ngIf="question.control.errors.required">
                                        This field is required
                                    </small>
                                    <small class="text-danger" *ngIf="question.control.errors.minlength">
                                        Too few characters. The minimum is {{ question.entryLimitMin }}
                                    </small>
                                    <small class="text-danger" *ngIf="question.control.errors.maxlength">
                                        Too many characters. The maximum is {{ question.entryLimit }}
                                    </small>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="questionType.ControlNumber">
                                <div class="JF-Question-Control-Number" [class._error]="question.control.touched && question.control.errors !== null">
                                    <input type="number" class="form-control" min="0" max="question.maxValue || 999" [placeholder]="question.hint"
                                            [formControl]="question.control" />
                                </div>
                                <div class="mt-1" *ngIf="question.control.touched && question.control.errors">
                                    <small class="text-danger" *ngIf="question.control.errors.required">
                                        This field is required
                                    </small>
                                    <small class="text-danger" *ngIf="question.control.errors.min">
                                        Input should not be less than {{ question.minValue }}
                                    </small>
                                    <small class="text-danger" *ngIf="question.control.errors.max">
                                        Input should be less than {{ question.maxValue }}
                                    </small>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="questionType.ControlCheckbox">
                                <div class="JF-Question-Control-Checkbox" [class._double]="question.spreadCols === 2" [class._error]="question.error">
                                    <label *ngFor="let option of question.options" class="JF-Question-Control-Checkbox-Option" [class._checked]="option.checked"
                                            (click)="checkOption($event, option)">
                                        <div class="_mark"></div>
                                        <span *ngIf="option.type !== 'other'">{{ option.value }}</span>
                                        <input *ngIf="option.type === 'other'" type="text" [(ngModel)]="option.value" [placeholder]="option.placeholder">
                                    </label>
                                </div>
                                <div class="mt-2" *ngIf="question.error">
                                    <small class="text-danger">
                                        At least one option should be selected
                                    </small>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="questionType.ControlImageChoice">
                                <div class="JF-Question-Control-ImageChoice" [class._error]="question.error">
                                    <label *ngFor="let option of question.options" class="JF-Question-Control-ImageChoice-Option" [class._checked]="option.checked"
                                            (click)="checkImage($event, option, question)">
                                        <div class="_mark" [class._radio]="!question.multiselect"></div>
                                        <div class="_image" [style.backgroundImage]="'url(' + option.link + ')'" [style.backgroundSize]="option.link?.includes('placeholder') ? '50px' : 'contain'">
                                        </div>
                                        <span>{{ option.text }}</span>
                                    </label>
                                </div>
                                <div class="mt-2" *ngIf="question.error">
                                    <small class="text-danger">
                                        At least one option should be selected
                                    </small>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="JF-Question-Footer">
                    <div class="JF-Question-Button _previous">
                        <span *ngIf="!first" (click)="prevQuestion(index)">
                            <one-icon class="mr-1" name="arrow-back-outline"></one-icon>
                            PREVIOUS
                        </span>
                    </div>
                    <div class="JF-Question-Button _next" [class._submit]="last" [class._submitting]="activeForm.submitting" (click)="processQuestion(index, last)">
                        <span *ngIf="!last">
                            NEXT
                           <one-icon class="ml-1" name="arrow-forward-outline"></one-icon>
                        </span>
                        <span *ngIf="last">
                            {{ activeForm.submitting ? 'PLEASE WAIT' : 'SUBMIT' }}
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<pf-log-out [entrance]="'pre-plan-survey'"></pf-log-out>
<pf-simple-loading-overlay [isLoading]="loading"></pf-simple-loading-overlay>
