import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import _ from 'lodash'
import moment from 'moment'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ApiService } from '../../../../../../core/services/api/api.service'
import { PreviewEvent } from '../../../../../../shared/components/modals/plugin-preview.modal/plugin-preview.modal.component'
import { StoreState } from '../../../../../../store/store.state'
import { getUserInfo } from '../../../../../../store/user/user.selectors'
import { PreviewService } from '../../../../services/preview.service'
import { PluginSetupResult } from '../../../models/quick-setup.model'
import { QuickSetupService } from '../../../services/quick-setup.service'
import { RouteHeaderUrl } from '../../../../../../shared/components/one-header/header-navigation.model'

@Component({
  templateUrl: './quick-setup-end.component.html',
  styleUrls: ['./quick-setup-end.component.scss']
})
export class QuickSetupEndComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription = new Subscription()
  exampleUrl: string
  isShop: boolean
  urlClickable: boolean
  isShopifyShop: boolean
  confetti = 0

  constructor(
    private quickSetupService: QuickSetupService,
    public previewService: PreviewService,
    private store: Store<StoreState>,
    private apiService: ApiService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(
        select(getUserInfo),
        filter((next) => !!next),
      ).subscribe((userShop: any) => {
        const shop = _.get(userShop, 'shop')
        if (shop) {
          this.urlClickable = true
          this.exampleUrl = shop.store_url.includes('http') ? `${shop.store_url}/` : `https://${shop.store_url}/`
          this.isShop = true
          this.isShopifyShop = shop.type === 'ShopifyShop'
        } else {
          this.urlClickable = false
          this.exampleUrl = 'https://yoursite.com/'
          this.isShop = false
        }
      }),
    )
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.confetti = 100
    }, 3000)
  }

  openSettings() {
    this.quickSetupService.closeDialog$.next(PluginSetupResult.NavigateToSettings)
  }

  closePopup(notNow?: boolean) {
    if (notNow === true) {
      this.quickSetupService.closeDialog$.next(PluginSetupResult.NotNow)
    } else {
      this.quickSetupService.closeDialog$.next(PluginSetupResult.Closed)
    }
  }

  navigateToNewVisitor() {
    const isPF = window.location.hostname.includes('prooffactor')
    const url = isPF ? `${this.exampleUrl}?prooffactor_new_visitor=true` : `${this.exampleUrl}?onestore_new_visitor=true`
    this.submitPreviewEvent(url)
    window.open(url, '_blank')
  }

  submitPreviewEvent(url: string) {
    const urlParts = window.location.pathname.split('/')
    let pageUrl = ''
    let afterCampaignId = false
    urlParts.forEach(part => {
      if (part && part.length && afterCampaignId) {
        pageUrl += `/${part}`
      }
      if (/\d/.test(part)) {
        afterCampaignId = true
      }
    })
    const event: PreviewEvent = {
      submit_url: url,
      plugin_name: this.quickSetupService.currentPlugin,
      trigger_page: pageUrl,
      created_at: moment().toISOString(),
      campaign: {
        id: this.quickSetupService.currentCampaign.id,
        name: this.quickSetupService.currentCampaign.name,
        active: this.quickSetupService.currentCampaign.active,
        valid: this.quickSetupService.currentCampaign.validity.status,
      },
    }
    this.subscription.add(
      this.apiService.post('/v1/me/interactions/preview/events', {event}).subscribe()
    )
  }

  goToApps() {
    this.quickSetupService.quickSetupEvent('Clicked Explore More - Quick Setup Success Modal', true)
    this.router.navigate([`/${RouteHeaderUrl.apps}`])
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
