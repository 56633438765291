import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core'
import { UserInfo } from '../../../../store/user/user.state'
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface PluginLockedModalData {
  userInfo: UserInfo
}

@Component({
    templateUrl: './plugin-locked.modal.component.html',
    styleUrls: ['./plugin-locked.modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      LoadingOverlayComponent,
    ]
})
export class PluginLockedModalComponent {

  constructor(
    public dialogRef: MatDialogRef<PluginLockedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PluginLockedModalData
  ) {
  }

  letsGo() {
    this.dialogRef.close({ purchase: true })
  }

}
