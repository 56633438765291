import { Component } from '@angular/core'
import { BasePromoComponent } from '../base-promo.component'
import { ApiPaymentService } from '../../../../../core/services/api/api-payment.service'
import { UntypedFormBuilder } from '@angular/forms'

@Component({
  selector: 'pf-special-offer-view',
  templateUrl: './special-offer-view.component.html',
  styleUrls: ['./special-offer-view.component.scss']
})

export class SpecialOfferViewComponent extends BasePromoComponent {
  constructor(
    public apiPaymentService: ApiPaymentService,
    public fb: UntypedFormBuilder
  ) {
    super(apiPaymentService, fb)
  }

}

