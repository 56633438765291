<ul class="Menu">
  <ng-container *ngFor="let item of items">
    <li *ngIf="!item.hidden" class="Menu__Item" [ngClass]="{'Menu__Group': item.group}">
      <a *ngIf="item.link && !item.group" class="Menu__Link" [routerLink]="item.link" routerLinkActive="active">
        <i *ngIf="item.icon" [class]="'Menu__Icon ' + item.icon" href=""></i>
        <span class="Menu__Title">{{item.title}}</span>
      </a>
      <a *ngIf="item.modalName && !item.group" class="Menu__Link" (click)="openModal($event, item.modalName)">
        <i *ngIf="item.icon" [class]="'Menu__Icon ' + item.icon" href=""></i>
        <span class="Menu__Title">{{item.title}}</span>
      </a>
      <a *ngIf="!item.link && item.url && !item.group" target=”_blank” class="Menu__Link" [href]="item.url">
        <i *ngIf="item.icon" [class]="'Menu__Icon ' + item.icon" href=""></i>
        <span class="Menu__Title">{{item.title}}</span>
      </a>
      <span *ngIf="item.group" class="Menu__Title">{{item.title}}</span>
    </li>
  </ng-container>
</ul>
