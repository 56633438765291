import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core'
import { Router } from '@angular/router'
import { SegmentAnalyticsService } from '../../../../shared/services/segment-analytics.service'

@Component({
  selector: 'pf-vip-button',
  templateUrl: './vip-button.component.html',
  styleUrls: ['./vip-button.component.scss']
})
export class VipButtonComponent implements OnInit {
  @Output() clicked: EventEmitter<any> = new EventEmitter()
  @Input() activeKnownCustomers = 0

  constructor(
    private segmentAnalyticsService: SegmentAnalyticsService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  click() {
    this.segmentAnalyticsService.track('Clicked VIP Onboarding Trigger Button', {
      activeKnownCustomers: this.activeKnownCustomers
    })
    this.router.navigateByUrl('/onboarding/vip-onboarding')
  }
}
