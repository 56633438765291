<div class="row" [formGroup]="form">

  <ng-container *ngIf="!readonly">
    <div class="col-md-4">
      <div class="form-group">
        <label>Country</label>
        <div class="position-relative" *ngIf="$any(form.controls['country']) as countryControl">
          <pf-simple-loading-overlay [isLoading]="countriesLoading"></pf-simple-loading-overlay>
          <select
            [formControl]="countryControl"
            class="form-control"
            (change)="onCountrySelect()"
            [compareWith]="compareLocation"
          >
            <ng-container *ngIf="countriesList$ | async as countriesList">
              <option [ngValue]="null" disabled selected>Select Country</option>
              <option *ngFor="let country of countriesList" [ngValue]="country">{{country.name}}</option>
            </ng-container>
          </select>
          <small class="text-danger" *ngIf="countryControl.invalid && countryControl.touched">
            Country is required
          </small>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>State</label>
        <div class="position-relative">
          <pf-simple-loading-overlay [isLoading]="statesLoading"></pf-simple-loading-overlay>
          <ng-container *ngIf="statesList$ | async as statesList">
            <select
              [formControl]="$any(form.controls['state'])"
              class="form-control"
              (change)="onStateSelect()"
              [compareWith]="compareLocation"
              [class.disabled]="statesList.length < 1"
            >
              <option [ngValue]="null" disabled selected>Select State</option>
              <option *ngFor="let state of statesList" [ngValue]="state">{{state.name}}</option>
            </select>
          </ng-container>
          <small class="text-danger" *ngIf="form.controls['state'] && form.controls['state'].invalid && form.controls['state'].touched">
            State is required
          </small>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label>City</label>
        <div class="position-relative">
          <input type="text" formControlName="city" class="form-control">
          <small class="text-danger" *ngIf="form.controls['city'] && form.controls['city'].invalid && form.controls['city'].touched">
            City is required
          </small>
        </div>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="readonly">
    <div class="col-md-4">
      <div class="form-group">
        <label>Country</label>
        <div class="position-relative">
          <input type="text" class="form-control" [value]="readonlyAddress.country" disabled>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>State</label>
        <div class="position-relative">
          <input type="text" class="form-control" [value]="readonlyAddress.state" disabled>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>City</label>
        <div class="position-relative">
          <input type="text" class="form-control" [value]="readonlyAddress.city" disabled>
        </div>
      </div>
    </div>
  </ng-container>



  <div class="col-md-12 mt-3">
    <button class="pf-button sm filled blue" (click)="this.submit$.next()"
            [disabled]="formInvalid"
            *ngIf="isSaveButtonEnabled && !readonly"
    >
      Save Location
    </button>
    <div *ngIf="locationSaved" class="mt-3 alert alert-success">Location details successfully saved</div>
    <div *ngIf="error" class="mt-3 alert alert-danger">{{error.error?.errors[0]?.code || error.statusText}}</div>
  </div>

</div>
