import { CommonModule } from '@angular/common';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { CampaignService } from '../../../../core/services/campaign.service'
import { CrispService } from '../../../../core/services/crisp.service';
import { UserService } from '../../../../core/services/user.service'
import { Campaign, CampaignState } from '../../../models/campaign/campaign'
import { OneIconComponent } from '../../one-icon/one-icon.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './campaign-limit-reached.modal.component.html',
    styleUrls: ['./campaign-limit-reached.modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      OneIconComponent,
    ]
})

export class CampaignLimitReachedModalComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription()
  plan: string
  limit: number
  campaigns: Campaign[] = []

  constructor(
    public dialogRef: MatDialogRef<CampaignLimitReachedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private crispService: CrispService,
    private userService: UserService,
    private campaignsService: CampaignService,
  ) {
  }

  ngOnInit(): void {
    const user = this.userService.userInfo
    this.plan = user.subscription.plan.name
    this.limit = user.subscription.campaigns_limit.limit

    this.subscription.add(
      this.campaignsService.getActiveCampaigns()
        .subscribe(next => {
          this.campaigns = next.campaigns
        }),
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  upgrade() {
    this.dialogRef.close('redirect')
    this.router.navigateByUrl(`/choose-plan`)
  }

  replace() {
    if (this.campaigns.length > 0) {
      this.subscription.add(this.campaignsService.setCampaignActive(this.campaigns[0].id, CampaignState.Off, this.data.quiet)
        .subscribe(() => {
          this.dialogRef.close('replace')
        }))
    } else {
      this.dialogRef.close()
    }
  }

  openCrisp() {
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', `Hi ONE+ Team, would it be possible for you to increase my active campaign limit for free?`]])
      crisp.push(['do', 'message:send', ['text', `I would be so grateful!`]])
      crisp.push(['do', 'message:send', ['text', `Thanks in advance!`]])
    }
    this.dialogRef.close()
  }
}
